import React from 'react';
import PropTypes from 'prop-types';

import { textFieldThemePropType } from 'constants/propTypes';

import {
  Input,
  Wrapper,
} from './style';

const TextField = ({
  hasError,
  name,
  onBlur,
  onChange,
  theme,
  type,
  value,
}) => (
  <Wrapper hasError={hasError} theme={theme}>
    <Input
      id={name}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      theme={theme}
      type={type}
      value={value}
      required
    />
  </Wrapper>
);

TextField.propTypes = {
  hasError: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  theme: textFieldThemePropType.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default TextField;
