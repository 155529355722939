import React from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from 'components/shared/LoadingSpinner';

import LinkButton from '../LinkButton';
import Button from '../Button';
import Modal from '../Modal';
import { LoadingText, LoadingWrapper, AsteriskLegend, CancelButtonWrapper, Footer } from './style';

const FormDialog = ({ children, ctaText, isSubmitting, onClose, onSubmit, text, title }) => {
  let content;

  if (isSubmitting) {
    content = (
      <LoadingWrapper>
        <LoadingText>Updating the reservation...</LoadingText>
        <LoadingSpinner />
      </LoadingWrapper>
    );
  } else {
    content = (
      <>
        <h2>{title}</h2>
        {text && <p>{text}</p>}
        <form>
          {children}
          <Footer>
            <div>
              <CancelButtonWrapper>
                <LinkButton onClick={onClose}>Cancel</LinkButton>
              </CancelButtonWrapper>
              <Button type="submit" onClick={onSubmit}>
                {ctaText}
              </Button>
            </div>
            <AsteriskLegend>Required field</AsteriskLegend>
          </Footer>
        </form>
      </>
    );
  }

  return <Modal onClose={onClose}>{content}</Modal>;
};

FormDialog.propTypes = {
  children: PropTypes.node.isRequired,
  ctaText: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
};

FormDialog.defaultProps = {
  ctaText: 'Submit',
  text: null,
};

export default FormDialog;
