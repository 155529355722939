const DAY_ID = 'dayId';
const OVERNIGHT_ID = 'overnightId';

const DAY_BACKEND_ID = 'day';
const OVERNIGHT_BACKEND_ID = 'overnight';

export default {
  [DAY_ID]: DAY_BACKEND_ID,
  [OVERNIGHT_ID]: OVERNIGHT_BACKEND_ID,
};
