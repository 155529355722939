export const errorSelector = (state) => state.reservations.error;
export const isLoadingSelector = (state) => state.reservations.isLoading;
export const didCancelSelector = (state) => state.reservations.didCancel;
export const reservationSelector = (state) => state.reservations.reservation;
export const isUpdatingReservation = (state) => state.reservations.isUpdating;
export const didResendEmailReservation = (state) => state.reservations.didResendEmail;

export const sgwDayOptionsSelector = (state) => state.reservations.sgwDayOptions.options;
export const sgwOvernightOptionsSelector = (state) => state.reservations.sgwOvernightOptions.options;
export const cucaDayOptionsSelector = (state) => state.reservations.cucaDayOptions.options;
export const cucaOvernightOptionsSelector = (state) => state.reservations.cucaOvernightOptions.options;
