import { ADMIN_CHANGE, RESET_ADMIN, PORTAL_CHANGE } from './actions';

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case RESET_ADMIN:
      return {
        ...state,
        error: null,
      };
    case ADMIN_CHANGE.PENDING:
    case PORTAL_CHANGE.PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case ADMIN_CHANGE.ERROR:
    case PORTAL_CHANGE.ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case ADMIN_CHANGE.SUCCESS:
    case PORTAL_CHANGE.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
