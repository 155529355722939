import styled from 'styled-components';
import { media } from 'styles';

export const PortalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const InstructionText = styled.h3`
  font-size: 2.2rem;
  color: #626262;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const OptionWrapper = styled.div`
  margin: 12px 0;
`;

export const ButtonWrapper = styled.div`
  margin: 40px 0;
`;

export const SubTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 30px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const DatePickerWrapper = styled.div`
  width: 100%;
`;
