import { createSelector } from 'reselect';

export const initAppErrorSelector = (state) => state.app.error;
export const isAppInitializingSelector = (state) => state.app.isLoading;
export const userClaimsSelector = (state) => state.app.userClaims;
export const portalDataSelector = (state) => state.app.portalData;

export const sgwMainOptionsSelector = (state) => state.app.sgwMainOptions;
export const cucaMainOptionsSelector = (state) => state.app.cucaMainOptions;

export const isPortalUnderMaintenanceSelector = createSelector(
  portalDataSelector,
  (portalData) => portalData && portalData.isPortalMaintenance
);

export const isCucamongaEnabledSelector = createSelector(
  portalDataSelector,
  (portalData) => portalData && portalData.isCucamongaEnabled
);

export const isSanGorgonioEnabledSelector = createSelector(
  portalDataSelector,
  (portalData) => portalData && portalData.isSanGorgonioEnabled
);

export const startDateSelector = createSelector(portalDataSelector, (portalData) => portalData && portalData.startDate);

export const availableSgwOvernightOptionsSelector = createSelector(
  sgwMainOptionsSelector,
  (mainOptions) => mainOptions && mainOptions.availableOvernightOptions
);

export const availableSgwOvernightTrailheadOptionsSelector = createSelector(
  sgwMainOptionsSelector,
  (mainOptions) => mainOptions && mainOptions.availableOvernightTrailheads
);

export const availableSgwDayOptionsSelector = createSelector(
  sgwMainOptionsSelector,
  (mainOptions) => mainOptions && mainOptions.availableDayOptions
);

export const sgwCustomNoteSelector = createSelector(
  sgwMainOptionsSelector,
  (mainOptions) => mainOptions && mainOptions.customNote
);

export const availableCucaOvernightOptionsSelector = createSelector(
  cucaMainOptionsSelector,
  (mainOptions) => mainOptions && mainOptions.availableOvernightOptions
);

export const availableCucaOvernightTrailheadOptionsSelector = createSelector(
  cucaMainOptionsSelector,
  (mainOptions) => mainOptions && mainOptions.availableOvernightTrailheads
);

export const availableCucaDayOptionsSelector = createSelector(
  cucaMainOptionsSelector,
  (mainOptions) => mainOptions && mainOptions.availableDayOptions
);

export const cucaCustomNoteSelector = createSelector(
  cucaMainOptionsSelector,
  (mainOptions) => mainOptions && mainOptions.customNote
);

export const isAuthorizedUserSelector = createSelector(userClaimsSelector, (userClaims) =>
  userClaims ? userClaims.isAdmin || userClaims.isCucamongaAdmin : false
);

export const isAdminUserSelector = createSelector(
  userClaimsSelector,
  (userClaims) => userClaims && !!userClaims.isAdmin
);

export const isCucamongaAdminUserSelector = createSelector(
  userClaimsSelector,
  (userClaims) => userClaims && !!userClaims.isCucamongaAdmin
);
