import React from 'react';
import PropTypes from 'prop-types';
import * as fieldNames from 'constants/reservationFormFields';

import TextField from 'components/shared/TextField';
import FormDialog from 'components/shared/FormDialog';

import { Cell, FieldTitle, Grid } from './style';

const EditReservationModal = ({ fields, isUpdating, onChange, onClose, onSubmit }) => (
  <FormDialog isSubmitting={isUpdating} onClose={onClose} onSubmit={onSubmit} title="Edit Reservation">
    <Grid>
      <Cell>
        <FieldTitle required>First Name</FieldTitle>
        <TextField
          labelText="First Name"
          name={fieldNames.FIRST_NAME}
          onChange={(name, value) => onChange(name, value, 'user')}
          value={fields.user.firstName}
        />
      </Cell>
      <Cell>
        <FieldTitle required>Last Name</FieldTitle>
        <TextField
          labelText="Last Name"
          name={fieldNames.LAST_NAME}
          onChange={(name, value) => onChange(name, value, 'user')}
          value={fields.user.lastName}
        />
      </Cell>
      <Cell>
        <FieldTitle required>Phone Number</FieldTitle>
        <TextField
          labelText="Last Name"
          name={fieldNames.PHONE_NUMBER}
          onChange={(name, value) => onChange(name, value, 'user')}
          value={fields.user.phoneNumber}
        />
      </Cell>
      <Cell>
        <FieldTitle required>Email</FieldTitle>
        <TextField labelText="Email" name={fieldNames.EMAIL} onChange={onChange} value={fields.email} />
      </Cell>
      <Cell>
        <FieldTitle required>Group Size (Max 12)</FieldTitle>
        <TextField
          labelText="Group Size"
          name={fieldNames.GROUP_NUMBER}
          onChange={(name, value) => onChange(name, value, 'user')}
          value={fields.user.groupNumber}
        />
      </Cell>
      <Cell>
        <FieldTitle required>Emergency Contact Full Name</FieldTitle>
        <TextField
          labelText="Emergency Name"
          name={fieldNames.EMERGENCY_NAME}
          onChange={(name, value) => onChange(name, value, 'user')}
          value={fields.user.emergencyName}
        />
      </Cell>
      <Cell>
        <FieldTitle required>Emergency Contact Phone Number</FieldTitle>
        <TextField
          labelText="Emergency Number"
          name={fieldNames.EMERGENCY_NUMBER}
          onChange={(name, value) => onChange(name, value, 'user')}
          value={fields.user.emergencyNumber}
        />
      </Cell>
      <Cell>
        <FieldTitle required>Car - License Number</FieldTitle>
        <TextField
          labelText="Car - License Number"
          name={fieldNames.CAR_LICENSE_NUMBER}
          onChange={(name, value) => onChange(name, value, 'car')}
          value={fields.user.car.licenseNumber}
        />
      </Cell>
      <Cell>
        <FieldTitle required>Car - Make</FieldTitle>
        <TextField
          labelText="Car - Make"
          name={fieldNames.CAR_MAKE}
          onChange={(name, value) => onChange(name, value, 'car')}
          value={fields.user.car.make}
        />
      </Cell>
      <Cell>
        <FieldTitle required>Car - Model</FieldTitle>
        <TextField
          labelText="Car - Model"
          name={fieldNames.CAR_MODEL}
          onChange={(name, value) => onChange(name, value, 'car')}
          value={fields.user.car.model}
        />
      </Cell>
      <Cell>
        <FieldTitle required>Car - State Registered</FieldTitle>
        <TextField
          labelText="Car - State"
          name={fieldNames.CAR_STATE}
          onChange={(name, value) => onChange(name, value, 'car')}
          value={fields.user.car.state}
        />
      </Cell>
      <Cell>
        <FieldTitle required>Address - Primary</FieldTitle>
        <TextField
          labelText="Address - Primary"
          name={fieldNames.ADDRESS_PRIMARY}
          onChange={(name, value) => onChange(name, value, 'address')}
          value={fields.user.address.primaryAddress}
        />
      </Cell>
      <Cell>
        <FieldTitle>Address - Extra Address Info</FieldTitle>
        <TextField
          labelText="Address - Extra Address Info"
          name={fieldNames.ADDRESS_EXTRA_ADDRESS_INFO}
          onChange={(name, value) => onChange(name, value, 'address')}
          value={fields.user.address.extraAddressInfo || ''}
        />
      </Cell>
      <Cell>
        <FieldTitle required>Address - City</FieldTitle>
        <TextField
          labelText="Address - City"
          name={fieldNames.ADDRESS_CITY}
          onChange={(name, value) => onChange(name, value, 'address')}
          value={fields.user.address.city}
        />
      </Cell>
      <Cell>
        <FieldTitle required>Address - Zip Code</FieldTitle>
        <TextField
          labelText="Address - Zip Code"
          name={fieldNames.ADDRESS_ZIPCODE}
          onChange={(name, value) => onChange(name, value, 'address')}
          value={fields.user.address.zipCode}
        />
      </Cell>
      <Cell>
        <FieldTitle required>Address - State</FieldTitle>
        <TextField
          labelText="Address - State"
          name={fieldNames.ADDRESS_STATE}
          onChange={(name, value) => onChange(name, value, 'address')}
          value={fields.user.address.state}
        />
      </Cell>
    </Grid>
  </FormDialog>
);

EditReservationModal.propTypes = {
  fields: PropTypes.objectOf(PropTypes.any).isRequired,
  isUpdating: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EditReservationModal;
