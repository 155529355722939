import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import Config from 'config'; // eslint-disable-line

const SENTRY_DSN_URL = 'https://6f1463ac857845d5854c3919e54fa55d@o237669.ingest.sentry.io/5884832';

Config.isAnalyticsEnabled &&
  Sentry.init({
    dsn: SENTRY_DSN_URL,
    environment: Config.environment,
    integrations: [
      new Integrations.CaptureConsole({
        levels: ['error'],
      }),
    ],
  });

export default Sentry;
