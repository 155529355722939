import { createAction, createAsyncAction, createAsyncTypes } from 'lib/reduxUtils';

export const RESET_RESERVATION = 'reservations/resetReservation';
export const resetReservation = createAction(RESET_RESERVATION);

export const FETCH_USER_RESERVATION = createAsyncTypes('reservation/fetchUserReservation');
export const fetchUserReservation = createAsyncAction(FETCH_USER_RESERVATION);

export const CANCEL_RESERVATION = createAsyncTypes('reservations/cancelReservation');
export const cancelReservation = createAsyncAction(CANCEL_RESERVATION);

export const EDIT_RESERVATION = createAsyncTypes('reservations/editReservation');
export const editReservation = createAsyncAction(EDIT_RESERVATION);

export const GENERATE_PERMIT = createAsyncTypes('reservations/generatePermit');
export const generatePermit = createAsyncAction(GENERATE_PERMIT);

export const RESEND_EMAIL = createAsyncTypes('reservations/resendEmail');
export const resendEmail = createAsyncAction(RESEND_EMAIL);
