import React from 'react';
import PropTypes from 'prop-types';
import { DescriptionText, Input, Label, ToggleSwitchWrapper, Wrapper } from './style';

const ToggleSwitch = ({ id, isToggleEnabled, onChange, text }) => (
  <Wrapper>
    <ToggleSwitchWrapper>
      <Input checked={isToggleEnabled} id={id} onChange={onChange} type="checkbox" />
      <Label htmlFor={id} />
    </ToggleSwitchWrapper>
    {text && <DescriptionText>{text}</DescriptionText>}
  </Wrapper>
);

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  isToggleEnabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
};

ToggleSwitch.defaultProps = {
  text: null,
};

export default ToggleSwitch;
