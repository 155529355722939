module.exports = {
  environment: 'prod',
  firebaseConfig: {
    apiKey: 'AIzaSyBkxXO7VjlSqUMph6ElWDZuJXStYDyXVQY',
    appId: '1:545263479464:web:f10627292162411bf6309d',
    authDomain: 'sgwa-permits.firebaseapp.com',
    databaseURL: 'https://sgwa-permits.firebaseio.com',
    measurementId: 'G-XW84RM3W9M',
    messagingSenderId: '545263479464',
    projectId: 'sgwa-permits',
    storageBucket: 'sgwa-permits.appspot.com',
  },
  isAnalyticsEnabled: true,
  reCAPTCHASiteKey: '6Lex4bIbAAAAAPfCvlZygE6lJcyzJ4yktcTZxCLP',
  segmentKey: 'sxpfA2460XLO4DvEIi8eDtlbBEVHRk0J',
};
