import React from 'react';
import PropTypes from 'prop-types';

import {
  LoadingMessage,
  Spinner,
} from './style';

const LoadingSpinner = ({ message }) => (
  <div>
    <Spinner />
    {message && (
      <LoadingMessage>
        {message}
      </LoadingMessage>
    )}
  </div>
);

LoadingSpinner.propTypes = {
  message: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  message: null,
};

export default LoadingSpinner;
