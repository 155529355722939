/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import track, { TrackingPropType } from 'react-tracking';

import Dropdown from 'components/shared/Dropdown';

const TypeAheadPrompt = ({ onChange, options, placeholder, tracking: { trackEvent } }) => {
  const optionsMapped = Object.keys(options).map((option) => ({
    label: options[option].title,
    value: options[option].id,
  }));

  const handleChange = (option) => {
    trackEvent({ event: 'Option Clicked', option });

    onChange(option);
  };

  return <Dropdown onChange={handleChange} options={optionsMapped} placeholder={placeholder} />;
};

TypeAheadPrompt.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
  tracking: TrackingPropType,
};

TypeAheadPrompt.defaultProps = {
  tracking: null,
};

const trackedTypeAheadPrompt = track({
  page: 'Type Ahead Prompt',
})(TypeAheadPrompt);

export default trackedTypeAheadPrompt;
