import { ADMIN_CHANGE, PORTAL_CHANGE } from 'store/admin/actions';
import { LOGOUT, LOGIN_USER, FETCH_MAIN_COLLECTION } from './actions';

export const INITIAL_STATE = {
  cucaMainOptions: null,
  error: null,
  isLoading: false,
  portalData: null,
  sgwMainOptions: null,
  userClaims: null,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOGOUT:
      return {
        ...state,
        userClaims: null,
      };
    case LOGIN_USER.PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case LOGIN_USER.ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case LOGIN_USER.SUCCESS:
      return {
        ...state,
        isLoading: false,
        userClaims: payload,
      };
    case FETCH_MAIN_COLLECTION.SUCCESS: {
      const { cucamongaData, portalData, sanGorgonioData } = payload;
      return {
        ...state,
        cucaMainOptions: cucamongaData.main.start,
        portalData,
        sgwMainOptions: sanGorgonioData ? sanGorgonioData.main.start : null,
      };
    }

    case PORTAL_CHANGE.SUCCESS:
      return {
        ...state,
        portalData: {
          ...state.portalData,
          ...payload,
        },
      };
    case ADMIN_CHANGE.SUCCESS: {
      const { data, wildernessKey } = payload;
      const prevData = state[wildernessKey];

      return {
        ...state,
        [wildernessKey]: {
          ...prevData,
          ...data,
        },
      };
    }

    default:
      return state;
  }
};
