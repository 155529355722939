import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  background-color: #f3f7f9;
  display: flex;
  overflow-x: auto;
  min-height: 100vh;
`;

export const NavMenuWrapper = styled.nav`
  position: relative;
  flex: 0 0 300px;
  padding: 20px;
  background-color: #f3f7f9;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 36px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  margin-top: 20px;
`;
