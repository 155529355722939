import { mix } from 'polished';

export default (variant, baseColor) => {
  switch (variant) {
    case 'background':
      return mix(0.6, '#fff', baseColor);
    case 'dark':
      return mix(0.05, '#000', baseColor);
    case 'light':
      return mix(0.4, '#fff', baseColor);
    case 'muted':
      return mix(0.9, '#fff', baseColor);
    case 'hover':
      return mix(0.1, '#fff', baseColor);
    default:
      return baseColor;
  }
};
