import Debug from 'debug';
import { call, put, takeLatest, select } from 'redux-saga/effects';

// import downloadPDF from 'lib/downloadPDF';
// import { isIOSChromeSelector } from 'store/userAgent/selectors';

import { GENERATE_PERMIT, generatePermit } from '../actions';
import { functions } from '../../../firebase';

const debug = Debug('sgwa:client-admin:store:reservations:sagas:generatePermit');

export function* generatePermitSaga({ payload }) {
  debug('Saga called', payload);

  try {
    const generatePermitFunction = functions.httpsCallable('generatePermit');
    const response = yield call(generatePermitFunction, payload);

    const pdf = response.data.pdfBase64;
    debug('PDF successful made');

    // const isIOSChrome = yield select(isIOSChromeSelector);
    // yield call(downloadPDF, pdf, isIOSChrome);

    yield put(generatePermit.success(pdf));
  } catch (e) {
    debug('Saga error', e);
    yield put(generatePermit.error(e));
  }
}

export default function* () {
  yield takeLatest(GENERATE_PERMIT.PENDING, generatePermitSaga);
}
