/* eslint-disable sort-keys  */

const type = {
  scale: {
    milli: {
      lineHeight: 16 / 12,
      size: '1.2rem',
    },
    centi: {
      lineHeight: 20 / 14,
      size: '1.4rem',
    },
    uno: {
      lineHeight: 24 / 16,
      size: '1.6rem',
    },
    hecto: {
      lineHeight: 26 / 18,
      size: '1.8rem',
    },
    kilo: {
      lineHeight: 28 / 21,
      size: '2.1rem',
    },
    giga: {
      lineHeight: 32 / 24,
      size: '2.4rem',
    },
    peta: {
      lineHeight: 44 / 32,
      size: '3.2rem',
    },
    yotta: {
      lineHeight: 60 / 47,
      size: '4.7rem',
    },
    bronto: {
      lineHeight: 72 / 54,
      size: '5.4rem',
    },
  },
  weight: {
    bold: 600,
    extraBold: 800,
    regular: 400,
    semiBold: 500,
  },
};

export default type;
