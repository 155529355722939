import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { usePrevious } from 'lib/customHooks';

import { toast } from 'react-toastify';

import * as reservationActions from 'store/reservations/actions';
import {
  didResendEmailReservation,
  reservationSelector,
  isLoadingSelector,
  errorSelector,
  didCancelSelector,
} from 'store/reservations/selectors';

import Reservations from './Reservations';

const mapStateToProps = (state) => ({
  didCancel: didCancelSelector(state),
  didResendEmail: didResendEmailReservation(state),
  error: errorSelector(state),
  isLoading: isLoadingSelector(state),
  reservation: reservationSelector(state),
});

const mapDispatchToProps = {
  cancelReservation: reservationActions.cancelReservation,
  fetchReservation: reservationActions.fetchUserReservation,
  resendEmail: reservationActions.resendEmail,
  resetReservation: reservationActions.resetReservation,
};

export const ReservationsContainer = ({
  cancelReservation,
  didCancel,
  didResendEmail,
  error,
  fetchReservation,
  isLoading,
  resendEmail,
  reservation,
  resetReservation,
}) => {
  const [permitId, setPermitId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => () => resetReservation(), []);

  const initialReservation = usePrevious(reservation);
  useEffect(() => {
    if (reservation !== initialReservation) setIsEditModalOpen(false);
  }, [reservation]);

  useEffect(() => {
    if (error) toast.error(error);
  }, [error]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (permitId) fetchReservation(permitId);
  };

  const handleChange = (value) => {
    const modifiedValue = value
      .toUpperCase()
      .trim()
      .replace(/[^a-zA-Z0-9]/g, '');

    setPermitId(modifiedValue);
  };

  const handleResendEmail = (event) => {
    event.preventDefault();

    if (reservation) resendEmail({ permitNumber: permitId, reservation });
  };

  const handleCancelReservation = (event) => {
    event.preventDefault();
    cancelReservation({ permitNumber: permitId, reservation });
  };

  const handleReset = (event) => {
    event.preventDefault();

    setPermitId(null);
    resetReservation();
  };

  const handleEditModalToggle = (event) => {
    event.preventDefault();
    setIsEditModalOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <Reservations
      didCancel={didCancel}
      didResendEmail={didResendEmail}
      isEditModalOpen={isEditModalOpen}
      isLoading={isLoading}
      onChange={handleChange}
      onCancelReservation={handleCancelReservation}
      onEditModalToggle={handleEditModalToggle}
      onResendEmail={handleResendEmail}
      onReset={handleReset}
      onSubmit={handleSubmit}
      permitId={permitId}
      reservation={reservation}
    />
  );
};

ReservationsContainer.propTypes = {
  cancelReservation: PropTypes.func.isRequired,
  didCancel: PropTypes.bool.isRequired,
  didResendEmail: PropTypes.bool.isRequired,
  error: PropTypes.string,
  fetchReservation: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  resendEmail: PropTypes.func.isRequired,
  reservation: PropTypes.objectOf(PropTypes.any),
  resetReservation: PropTypes.func.isRequired,
};

ReservationsContainer.defaultProps = {
  error: null,
  reservation: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationsContainer);
