import { RESET_STORE, SEND_EMAIL } from './actions';

export const INITIAL_STATE = {
  didSendEmail: false,
  error: null,
  isSending: false,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case RESET_STORE:
      return INITIAL_STATE;
    case SEND_EMAIL.PENDING:
      return {
        ...state,
        didSendEmail: false,
        error: null,
        isSending: true,
      };
    case SEND_EMAIL.ERROR:
      return {
        ...state,
        error: payload,
        isSending: false,
      };
    case SEND_EMAIL.SUCCESS:
      return {
        ...state,
        didSendEmail: true,
        isSending: false,
      };
    default:
      return state;
  }
};
