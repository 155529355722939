import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/shared/Button';
import StartAndEndDatePicker from 'components/shared/StartAndEndDatePicker';
import TypeAheadPrompt from 'components/shared/TypeAheadPrompt';
import LoadingSpinner from 'components/shared/LoadingSpinner';

import {
  CheckboxWrapper,
  Checkbox,
  CheckboxLabel,
  DownloadsWrapper,
  InstructionText,
  OptionWrapper,
  SubTitle,
  TypeAheadWrapper,
  ListDivider,
} from './style';

const typeOptions = {
  day: {
    id: 'dayId',
    title: 'Day Hike Permits',
  },
  overnight: {
    id: 'overnightId',
    title: 'Overnight Permits',
  },
};

const Downloads = ({
  isCancelledPermits,
  isDownloadButtonDisabled,
  isDownloading,
  isFullSummary,
  locationTypeOptions,
  onCancelledPermits,
  onCounterDatesSelected,
  onDatesSelected,
  onDownloadCounterData,
  onDownloadReservations,
  onFullSummary,
  onLocationType,
  onSelectedType,
}) => {
  let content;
  if (isDownloading) {
    content = (
      <>
        <SubTitle>Downloading the CSV. This may take a minute...</SubTitle>
        <LoadingSpinner />
      </>
    );
  } else {
    content = (
      <>
        <OptionWrapper>
          <InstructionText>
            Counter Data. Download the total number of reservations and cancellations by day as a CSV.
          </InstructionText>
        </OptionWrapper>
        <TypeAheadWrapper>
          <TypeAheadPrompt
            options={locationTypeOptions}
            onChange={onLocationType}
            placeholder="Select a Wilderness first"
          />
        </TypeAheadWrapper>
        <OptionWrapper>
          <StartAndEndDatePicker onChange={onCounterDatesSelected} />
        </OptionWrapper>
        <OptionWrapper>
          <Button onClick={onDownloadCounterData}>Download</Button>
        </OptionWrapper>
        <ListDivider />
        <OptionWrapper>
          <InstructionText>Reservation Data. Download reservations by the selected days as a CSV.</InstructionText>
        </OptionWrapper>
        <OptionWrapper>
          <CheckboxWrapper>
            <Checkbox
              type="checkbox"
              checked={isCancelledPermits}
              id="isCancelledPermits"
              name="isCancelledPermits"
              onChange={(e) => onCancelledPermits(e.target.checked)}
            />
            <CheckboxLabel htmlFor="isEmailSignedUp">Download only cancelled permits? (optional)</CheckboxLabel>
          </CheckboxWrapper>
        </OptionWrapper>
        <OptionWrapper>
          <CheckboxWrapper>
            <Checkbox
              type="checkbox"
              checked={isFullSummary}
              id="isFullSummary"
              name="isFullSummary"
              onChange={(e) => onFullSummary(e.target.checked)}
            />
            <CheckboxLabel htmlFor="isEmailSignedUp">Include all fields in download? (optional)</CheckboxLabel>
          </CheckboxWrapper>
        </OptionWrapper>
        <TypeAheadWrapper>
          <TypeAheadPrompt
            options={locationTypeOptions}
            onChange={onLocationType}
            placeholder="Select a Wilderness first"
          />
        </TypeAheadWrapper>
        <TypeAheadWrapper>
          <TypeAheadPrompt options={typeOptions} onChange={onSelectedType} placeholder="Select a permit type" />
        </TypeAheadWrapper>
        <OptionWrapper>
          <StartAndEndDatePicker onChange={onDatesSelected} />
        </OptionWrapper>
        <OptionWrapper>
          <Button disabled={isDownloadButtonDisabled} onClick={onDownloadReservations}>
            Download
          </Button>
        </OptionWrapper>
      </>
    );
  }

  return <DownloadsWrapper>{content}</DownloadsWrapper>;
};

Downloads.propTypes = {
  isCancelledPermits: PropTypes.bool.isRequired,
  isDownloadButtonDisabled: PropTypes.bool.isRequired,
  isDownloading: PropTypes.bool.isRequired,
  isFullSummary: PropTypes.bool.isRequired,
  locationTypeOptions: PropTypes.objectOf(PropTypes.string).isRequired,
  onCancelledPermits: PropTypes.func.isRequired,
  onCounterDatesSelected: PropTypes.func.isRequired,
  onDatesSelected: PropTypes.func.isRequired,
  onDownloadCounterData: PropTypes.func.isRequired,
  onDownloadReservations: PropTypes.func.isRequired,
  onFullSummary: PropTypes.func.isRequired,
  onLocationType: PropTypes.func.isRequired,
  onSelectedType: PropTypes.func.isRequired,
};

export default Downloads;
