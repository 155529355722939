export const SAN_GORGONIO_WILDERNESS_ID = 'sanGorgonioId';
export const CUCAMONGA_WILDERNESS_ID = 'cucamongaId';

export const SAN_GORGONIO_WILDERNESS_BACKEND_ID = 'san_gorgonio';
export const CUCAMONGA_WILDERNESS_BACKEND_ID = 'cucamonga';

export const locationTypeTitles = {
  [CUCAMONGA_WILDERNESS_ID]: 'Cucamonga Wilderness',
  [SAN_GORGONIO_WILDERNESS_ID]: 'San Gorgonio Wilderness',
};

export const locationTypeFromBackendTitles = {
  [CUCAMONGA_WILDERNESS_BACKEND_ID]: 'Cucamonga Wilderness',
  [SAN_GORGONIO_WILDERNESS_BACKEND_ID]: 'San Gorgonio Wilderness',
};
