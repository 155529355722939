import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FocusTrap from 'components/shared/FocusTrap';
import {
  CloseButton,
  ModalContentWrapper,
  ModalWrapper,
} from './style';

class Modal extends PureComponent {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
  }

  componentDidMount() {
    document.body.style['overflow-y'] = 'hidden';
    window.addEventListener('mousedown', this.handleMousedown);
    window.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    document.body.style['overflow-y'] = 'visible';
    window.removeEventListener('mousedown', this.handleMousedown);
    window.removeEventListener('keyup', this.handleKeyUp);
  }

  handleMousedown = event => {
    const { isRequired, onClose } = this.props;

    if (this.modalRef.current.contains(event.target) || isRequired) {
      return;
    }

    onClose();
  };

  handleKeyUp = event => {
    const { isRequired, onClose } = this.props;

    if ((event.key === 'Escape' || event.key === 'Esc') && !isRequired) {
      onClose();
    }
  };


  render() {
    const { isRequired, onClose } = this.props;
    return (
      <FocusTrap>
        <ModalWrapper>
          <ModalContentWrapper ref={this.modalRef}>
            {!isRequired && (
              <CloseButton onClick={onClose}>&times;</CloseButton>
            )}
            {this.props.children}
          </ModalContentWrapper>
        </ModalWrapper>
      </FocusTrap>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isRequired: PropTypes.bool,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  isRequired: false,
  onClose: null,
};

export default Modal;
