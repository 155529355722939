export const ADDRESS = 'address';
export const ADDRESS_CITY = 'city';
export const ADDRESS_EXTRA_ADDRESS_INFO = 'extraAddressInfo';
export const ADDRESS_PRIMARY = 'primaryAddress';
export const ADDRESS_STATE = 'state';
export const ADDRESS_ZIPCODE = 'zipCode';
export const CAR = 'car';
export const CAR_LICENSE_NUMBER = 'licenseNumber';
export const CAR_MAKE = 'make';
export const CAR_MODEL = 'model';
export const CAR_STATE = 'state';
export const EMAIL = 'email';
export const EMERGENCY_NAME = 'emergencyName';
export const EMERGENCY_NUMBER = 'emergencyNumber';
export const FIRST_NAME = 'firstName';
export const GROUP_NUMBER = 'groupNumber';
export const IS_EMAIL_SIGNED_UP = 'isEmailSignedUp';
export const LAST_NAME = 'lastName';
export const PHONE_NUMBER = 'phoneNumber';
