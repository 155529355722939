import { RESET_DOWNLOAD, DOWNLOAD_RESERVATIONS, DOWNLOAD_COUNTER_DATA } from './actions';

export const INITIAL_STATE = {
  csv: null,
  error: null,
  isDownloading: false,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case RESET_DOWNLOAD:
      return INITIAL_STATE;
    case DOWNLOAD_RESERVATIONS.PENDING:
      return {
        ...state,
        csv: null,
        error: null,
        isDownloading: true,
      };
    case DOWNLOAD_RESERVATIONS.ERROR:
      return {
        ...state,
        error: payload,
        isDownloading: false,
      };
    case DOWNLOAD_RESERVATIONS.SUCCESS:
      return {
        ...state,
        csv: payload,
        isDownloading: false,
      };
    case DOWNLOAD_COUNTER_DATA.PENDING:
      return {
        ...state,
        csv: null,
        error: null,
        isDownloading: true,
      };
    case DOWNLOAD_COUNTER_DATA.ERROR:
      return {
        ...state,
        error: payload,
        isDownloading: false,
      };
    case DOWNLOAD_COUNTER_DATA.SUCCESS:
      return {
        ...state,
        csv: payload,
        isDownloading: false,
      };
    default:
      return state;
  }
};
