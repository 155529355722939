import { colors } from 'styles';

export default {
  control: provided => ({
    ...provided,
    border: 'solid 2px #2E5941',
    'border-radius': '4px',
    borderColor: '#2E5941',
  }),
  option: provided => ({
    ...provided,
    color: colors.sierra.base,
  }),
  singleValue: provided => ({
    ...provided,
    padding: '0px',
  }),
  valueContainer: provided => ({
    ...provided,
    background: colors.white,
    color: colors.sierra.base,
    height: '60px',
    padding: '16px 20px',
    width: '100%',
  }),
};
