import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from 'styles';

import { textFieldThemePropType } from 'constants/propTypes';
import mergeDeep from 'lib/mergeDeep';

import TextField from './TextField';

const DEFAULT_THEME = {
  container: {
    background: 'transparent',
    border: {
      default: '#dbe1e8',
      error: '#f92b57',
    },
  },
  input: {
    color: {
      default: colors.sierra.base,
    },
  },
  label: {
    color: {
      default: '#dbe1e8',
      error: '#f92b57',
    },
  },
};

class TextFieldContainer extends Component {
  constructor(props) {
    super(props);

    if (props.theme) {
      this.theme = mergeDeep({}, DEFAULT_THEME, props.theme);
    } else {
      this.theme = DEFAULT_THEME;
    }
    this.state = {
      hasError: false,
    };
  }

  handleBlur = event => {
    const { name, value } = event.target;

    this.props.validate && this.updateValidity(name, value);
    this.props.onBlur && this.props.onBlur(event);
  };

  handleChange = event => {
    if (this.state.hasError) this.setState({ hasError: false });

    const { name, value } = event.target;

    this.props.onChange(name, value);
  };

  updateValidity = value => {
    const errorType = this.props.validate(value) || null;

    this.setState({ hasError: !!errorType });
  };

  render() {
    const {
      hasExternalError,
      labelText,
      name,
      toolTipText,
      type,
      value,
    } = this.props;
    const { hasError } = this.state;

    return (
      <TextField
        hasError={hasError || hasExternalError}
        id={name}
        labelText={labelText}
        name={name}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        theme={this.theme}
        toolTipText={toolTipText}
        type={type}
        value={value}
        required
      />
    );
  }
}

TextFieldContainer.propTypes = {
  hasExternalError: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  theme: textFieldThemePropType,
  toolTipText: PropTypes.string,
  type: PropTypes.string,
  validate: PropTypes.func,
  value: PropTypes.string.isRequired,
};

TextFieldContainer.defaultProps = {
  hasExternalError: false,
  onBlur: null,
  theme: null,
  toolTipText: '',
  type: 'text',
  validate: null,
};

export default TextFieldContainer;
