/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

const ValidatedRoute = ({
  component: Component,
  redirectPathname,
  shouldRender,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (shouldRender
      ? <Component {...props} />
      : (
        <Redirect
          to={{
            pathname: redirectPathname,
            state: { from: props.location },
          }}
        />
      )
    )}
  />
);

ValidatedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.elementType,
  ]).isRequired,
  redirectPathname: PropTypes.string.isRequired,
  shouldRender: PropTypes.bool.isRequired,
};

export default ValidatedRoute;
