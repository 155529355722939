import Debug from 'debug';
import { call, put, takeLatest } from 'redux-saga/effects';
import awaitAsyncAction from 'lib/awaitAsyncAction';

import { auth } from '../../../firebase';
import { FETCH_MAIN_COLLECTION, LOGIN_USER, loginUser, fetchMainCollection } from '../actions';

const debug = Debug('sgwa:client-admin:store:app:sagas:loginUser');

export function* loginSaga({ payload }) {
  const { email, password } = payload;
  debug('Saga called', payload);

  try {
    const user = yield call([auth, auth.signInWithEmailAndPassword], email, password);
    debug('User logged in', user);

    const { currentUser } = auth;
    const token = yield call([currentUser, currentUser.getIdTokenResult]);

    const userClaims = token.claims;
    debug('User claims', userClaims);

    yield put(fetchMainCollection(userClaims));
    const [, errorAction] = yield call(awaitAsyncAction, FETCH_MAIN_COLLECTION);

    if (errorAction) {
      const { payload: error } = errorAction;
      if (error) throw error;
    }

    yield put(loginUser.success(userClaims));
  } catch (e) {
    debug('Saga error', e.message);
    yield put(loginUser.error(e.message));
  }
}

export default function* () {
  yield takeLatest(LOGIN_USER.PENDING, loginSaga);
}
