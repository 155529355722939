import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% { background-position: -468px 0; }
  100% { background-position: 468px 0; }
`;

const FieldPlaceholder = styled.div`
  height: 22px;
  display: inline-block;

  &:before {
    height: 18px;
    width: ${p => p.width || '150px'};
    content: '';
    display: block;
    border-radius: 12px;
    background-color: #e2e5e8;
    background-image: linear-gradient(to right, #e2e5e8 0%, #f0f2f3 40%, #e2e5e8 100%);
    background-repeat: no-repeat;
    background-size: 200px 100%;
    background-position: 0 0;

    animation: ${shimmer} 1s linear infinite forwards;
  }
`;

export default FieldPlaceholder;
