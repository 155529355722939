import { FETCH_MAIN_COLLECTION } from 'store/app/actions';
import {
  CANCEL_RESERVATION,
  EDIT_RESERVATION,
  FETCH_USER_RESERVATION,
  RESEND_EMAIL,
  RESET_RESERVATION,
} from './actions';

export const INITIAL_STATE = {
  cucaDayOptions: null,
  cucaOvernightOptions: null,
  didCancel: false,
  didResendEmail: false,
  error: null,
  isLoading: false,
  isUpdating: false,
  reservation: null,
  sgwDayOptions: null,
  sgwOvernightOptions: null,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case RESET_RESERVATION:
      return {
        ...state,
        didCancel: false,
        didResendEmail: false,
        error: null,
        reservation: null,
      };
    case EDIT_RESERVATION.PENDING:
      return {
        ...state,
        error: null,
        isUpdating: true,
      };
    case EDIT_RESERVATION.ERROR:
      return {
        ...state,
        error: payload,
        isUpdating: false,
      };
    case EDIT_RESERVATION.SUCCESS:
      return {
        ...state,
        isUpdating: false,
        reservation: payload,
      };
    case RESEND_EMAIL.PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case RESEND_EMAIL.ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case RESEND_EMAIL.SUCCESS:
      return {
        ...state,
        didResendEmail: true,
        isLoading: false,
      };
    case CANCEL_RESERVATION.PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case CANCEL_RESERVATION.ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case CANCEL_RESERVATION.SUCCESS:
      return {
        ...state,
        didCancel: true,
        isLoading: false,
      };
    case FETCH_USER_RESERVATION.PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case FETCH_USER_RESERVATION.ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case FETCH_USER_RESERVATION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        reservation: payload,
      };
    case FETCH_MAIN_COLLECTION.SUCCESS: {
      const { cucamongaData, sanGorgonioData } = payload;
      return {
        ...state,
        cucaDayOptions: cucamongaData.day,
        cucaOvernightOptions: cucamongaData.overnight,
        sgwDayOptions: sanGorgonioData && sanGorgonioData.day,
        sgwOvernightOptions: sanGorgonioData && sanGorgonioData.overnight,
      };
    }
    default:
      return state;
  }
};
