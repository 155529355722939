import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Label,
  CheckboxWrapper,
} from './style';

const Checkbox = ({
  autoFocus,
  children,
  id,
  isChecked,
  onChange,
}) => (
  <CheckboxWrapper>
    <Input
      autoFocus={autoFocus}
      checked={isChecked}
      id={id}
      onChange={onChange}
      type="checkbox"
    />
    <Label htmlFor={id}>
      {children}
    </Label>
  </CheckboxWrapper>
);

Checkbox.propTypes = {
  autoFocus: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  autoFocus: false,
};

export default Checkbox;
