/* eslint-disable max-len */

import Debug from 'debug';
import { call, put, takeLatest } from 'redux-saga/effects';

import * as PermitTypes from 'constants/permitTypes';

import { batchCancelDayReservation, batchCancelOvernightReservation } from './reservationUtils';

import { CANCEL_RESERVATION, cancelReservation } from '../actions';
import { firestore } from '../../../firebase';

const debug = Debug('sgwa:client-admin:store:reservations:sagas:cancelReservation');

export function* cancelReservationSaga({ payload }) {
  const { permitNumber, reservation } = payload;
  debug('Saga called', payload);

  try {
    const { isCancelled, reservationType } = reservation;
    if (isCancelled) throw Error('This reservation was already cancelled');

    const batch = firestore.batch();
    const userPayload = {
      ...reservation,
      permitNumber,
    };

    if (reservationType === PermitTypes.DAY_ID) yield* batchCancelDayReservation(batch, userPayload);
    else yield* batchCancelOvernightReservation(batch, userPayload);

    yield call([batch, batch.commit]);
    debug('Batch call successfully made');

    yield put(cancelReservation.success());
  } catch (e) {
    debug('Saga error', e.message);
    yield put(cancelReservation.error(e.message));
  }
}

export default function* () {
  yield takeLatest(CANCEL_RESERVATION.PENDING, cancelReservationSaga);
}
