import colorVariant from 'styles/functions/colorVariant';

const baseColors = {
  black: '#000',
  cascade: '#6f7d8d',
  catalina: '#d0006f',
  cruz: '#922f90',
  gold: '#ffdc9e',
  helen: '#f92b57',
  malibu: '#fa3',
  pacific: '#2393d7',
  rainier: '#e11d39',
  ridge: '#f6f7f9',
  rocky: '#dddddd',
  shasta: '#2088b3',
  sierra: '#21242c',
  tahoe: '#2cb3ad',
  white: '#fff',
  yosemite: '#5dca9c',
};

const colors = {
  black: baseColors.black,
  cascade: {
    background: colorVariant('background', baseColors.cascade),
    base: baseColors.cascade,
    dark: colorVariant('dark', baseColors.cascade),
    light: colorVariant('light', baseColors.cascade),
    muted: colorVariant('muted', baseColors.cascade),
  },
  catalina: {
    background: colorVariant('background', baseColors.catalina),
    base: baseColors.catalina,
    dark: colorVariant('dark', baseColors.catalina),
    light: colorVariant('light', baseColors.catalina),
    muted: colorVariant('muted', baseColors.catalina),
  },
  cruz: {
    background: colorVariant('background', baseColors.cruz),
    base: baseColors.cruz,
    dark: colorVariant('dark', baseColors.cruz),
    light: colorVariant('light', baseColors.cruz),
    muted: colorVariant('muted', baseColors.cruz),
  },
  gold: {
    background: colorVariant('background', baseColors.gold),
    base: baseColors.gold,
    dark: colorVariant('dark', baseColors.gold),
    hover: colorVariant('hover', baseColors.gold),
    light: colorVariant('light', baseColors.gold),
    muted: colorVariant('muted', baseColors.gold),
  },
  helen: {
    background: colorVariant('background', baseColors.helen),
    base: baseColors.helen,
    dark: colorVariant('dark', baseColors.helen),
    light: colorVariant('light', baseColors.helen),
    muted: colorVariant('muted', baseColors.helen),
  },
  malibu: {
    background: colorVariant('background', baseColors.malibu),
    base: baseColors.malibu,
    dark: colorVariant('dark', baseColors.malibu),
    light: colorVariant('light', baseColors.malibu),
    muted: colorVariant('muted', baseColors.malibu),
  },
  pacific: {
    background: colorVariant('background', baseColors.pacific),
    base: baseColors.pacific,
    dark: colorVariant('dark', baseColors.pacific),
    hover: colorVariant('hover', baseColors.pacific),
    light: colorVariant('light', baseColors.pacific),
    muted: colorVariant('muted', baseColors.pacific),
  },
  rainier: {
    background: colorVariant('background', baseColors.rainier),
    base: baseColors.rainier,
    dark: colorVariant('dark', baseColors.rainier),
    light: colorVariant('light', baseColors.rainier),
    muted: colorVariant('muted', baseColors.rainier),
  },
  ridge: {
    background: colorVariant('background', baseColors.ridge),
    base: baseColors.ridge,
    dark: colorVariant('dark', baseColors.ridge),
    light: colorVariant('light', baseColors.ridge),
    muted: colorVariant('muted', baseColors.ridge),
  },
  rocky: {
    background: colorVariant('background', baseColors.rocky),
    base: baseColors.rocky,
    dark: colorVariant('dark', baseColors.rocky),
    light: colorVariant('light', baseColors.rocky),
    muted: colorVariant('muted', baseColors.rocky),
  },
  shasta: {
    background: colorVariant('background', baseColors.shasta),
    base: baseColors.shasta,
    dark: colorVariant('dark', baseColors.shasta),
    hover: colorVariant('hover', baseColors.shasta),
    light: colorVariant('light', baseColors.shasta),
    muted: colorVariant('muted', baseColors.shasta),
  },
  sierra: {
    background: colorVariant('background', baseColors.sierra),
    base: baseColors.sierra,
    dark: colorVariant('dark', baseColors.sierra),
    light: colorVariant('light', baseColors.sierra),
    muted: colorVariant('muted', baseColors.sierra),
  },
  tahoe: {
    background: colorVariant('background', baseColors.tahoe),
    base: baseColors.tahoe,
    dark: colorVariant('dark', baseColors.tahoe),
    light: colorVariant('light', baseColors.tahoe),
    muted: colorVariant('muted', baseColors.tahoe),
  },
  white: baseColors.white,
  yosemite: {
    background: colorVariant('background', baseColors.yosemite),
    base: baseColors.yosemite,
    dark: colorVariant('dark', baseColors.yosemite),
    hover: colorVariant('hover', baseColors.yosemite),
    light: colorVariant('light', baseColors.yosemite),
    muted: colorVariant('muted', baseColors.yosemite),
  },
};

export default colors;
