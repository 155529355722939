import firebase from 'firebase/compat/app';
import 'firebase/compat/app-check';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

import Config from 'config'; // eslint-disable-line

firebase.initializeApp(Config.firebaseConfig);

if (Config.environment === 'dev') window.firebase = firebase;

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.functions();
export const firebaseInstance = firebase;

const appCheck = firebase.appCheck();
appCheck.activate(Config.reCAPTCHASiteKey);

export default firebase;
