/* eslint-disable one-var */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import Moment from 'moment';
import * as PermitTypes from 'constants/permitTypes';

import { Body, Header, PermitTitle, SubText, SummaryWrapper, Text, TypeTitle } from './style';

const reservationMappings = {
  [PermitTypes.DAY_ID]: 'Day',
  [PermitTypes.OVERNIGHT_ID]: 'Overnight',
};

const locationTypeMappings = {
  cucamonga: 'Cucamonga Wilderness',
  san_gorgonio: 'San Gorgonio Wilderness',
};

export const DocumentSummary = ({ isDetailed, reservation }) => {
  const { email, isCancelled, locationType, permitNumber, reservationType, selection } = reservation;
  let firstName;
  let lastName;
  let city, extraAddressInfo, primaryAddress, state, zipCode;
  let licenseNumber, make, model;
  let emergencyName, emergencyNumber;

  if (reservation.user) {
    ({ firstName } = reservation.user);
    ({ lastName } = reservation.user);
  } else {
    // Old reservation object prior June 2021
    ({ firstName } = reservation);
    ({ lastName } = reservation);
  }

  const locationTypeTitle = locationTypeMappings[locationType];
  const reservationTypeTitle = reservationMappings[reservationType];
  let site;

  if (reservation.user) {
    if (reservationType === PermitTypes.DAY_ID) {
      site = (
        <>
          <Text>{`At ${selection.title}`}</Text>
          <SubText>{`${selection.date}`}</SubText>
        </>
      );
    } else {
      site = Object.keys(selection).map((siteId, index) => {
        const { end, start } = selection[siteId];

        return (
          <React.Fragment key={`${start.id}_${index}`}>
            <Text>{`At ${start.title}`}</Text>
            <SubText>{`${start.date} to ${end.date}`}</SubText>
          </React.Fragment>
        );
      });
    }
  }

  // Old reservation object prior June 2021
  if (reservation.date) {
    const { date, trail } = reservation;

    if (reservationType === PermitTypes.DAY_ID) {
      site = (
        <>
          <Text>{`At ${trail.title}`}</Text>
          <SubText>{`${date.formatDate}`}</SubText>
        </>
      );
    } else {
      site = Object.keys(date).map((trailId, index) => {
        const selectedTrail = date[trailId];

        const start = Moment(selectedTrail.dates.start).local().format('LL');
        const end = Moment(selectedTrail.dates.end).local().format('LL');
        return (
          <React.Fragment key={`${selectedTrail.trail.id}_${index}`}>
            <Text>{`At ${selectedTrail.trail.title}`}</Text>
            <SubText>{`${start} to ${end}`}</SubText>
          </React.Fragment>
        );
      });
    }
  }

  if (isDetailed) {
    if (reservation.user) {
      ({ emergencyName, emergencyNumber } = reservation.user);
      ({ city, extraAddressInfo, primaryAddress, state, zipCode } = reservation.user.address);
      ({ licenseNumber, make, model } = reservation.user.car);
    } else {
      // Old reservation object prior June 2021
      ({ emergencyName, emergencyNumber } = reservation);
      ({ city, extraAddressInfo, primaryAddress, state, zipCode } = reservation.address);
      ({ licenseNumber, make, model } = reservation.car);
    }
  }

  return (
    <SummaryWrapper>
      <Header>
        <TypeTitle>
          {locationTypeTitle} {reservationTypeTitle} Permit
        </TypeTitle>
        <PermitTitle>#{permitNumber}</PermitTitle>
      </Header>
      <Body>
        <Text>{`${firstName} ${lastName}`}</Text>
        {site}
        {isDetailed && (
          <>
            <Text>{`Email: ${email}`}</Text>
            <Text>{`Emergency Contact: ${emergencyName}, ${emergencyNumber}`}</Text>
            <Text>{`Car: ${licenseNumber}, ${make}, ${model}, ${state}`}</Text>
            <Text>
              {`Address: ${primaryAddress}, ${
                extraAddressInfo ? `${extraAddressInfo},` : ''
              } ${city}, ${zipCode}, ${state}`}
            </Text>
            <Text>{`Is reservation cancelled? ${isCancelled ? 'Yes' : 'No'}`}</Text>
          </>
        )}
      </Body>
    </SummaryWrapper>
  );
};

DocumentSummary.propTypes = {
  isDetailed: PropTypes.bool,
  reservation: PropTypes.objectOf(PropTypes.any).isRequired,
};

DocumentSummary.defaultProps = {
  isDetailed: false,
};

export default DocumentSummary;
