import Debug from 'debug';
import { call, put, takeLatest } from 'redux-saga/effects';

import { firestore } from '../../../firebase';
import { ADMIN_CHANGE, adminChange } from '../actions';

const debug = Debug('sgwa:permit-portal:store:admin:sagas:adminChange');

const reducerMapping = {
  cucamonga: 'cucaMainOptions',
  san_gorgonio: 'sgwMainOptions',
};

export function* adminChangeSaga({ payload }) {
  const { data, locationType } = payload;
  debug('Saga called', data);

  const payloadData = { start: { ...data } };
  try {
    const ref = firestore.doc(`${locationType}/main`);
    yield call([ref, ref.set], payloadData, { merge: true });

    const wildernessKey = reducerMapping[locationType];

    yield put(adminChange.success({ data, wildernessKey }));
  } catch (e) {
    debug('Saga error', e.message);
    yield put(adminChange.error(e.message));
  }
}

export default function* () {
  yield takeLatest(ADMIN_CHANGE.PENDING, adminChangeSaga);
}
