import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { addRemoveElementFromArray, areArraysEqual } from 'lib/utils';

import * as adminActions from 'store/admin/actions';

import { cucaDayOptionsSelector, cucaOvernightOptionsSelector } from 'store/reservations/selectors';

import {
  availableCucaDayOptionsSelector,
  availableCucaOvernightOptionsSelector,
  availableCucaOvernightTrailheadOptionsSelector,
  cucaCustomNoteSelector,
} from 'store/app/selectors';

import Portal from './Portal';

const mapStateToProps = (state) => ({
  availableDayOptions: availableCucaDayOptionsSelector(state),
  availableOvernightOptions: availableCucaOvernightOptionsSelector(state),
  availableOvernightTrailheadOptions: availableCucaOvernightTrailheadOptionsSelector(state),
  customNote: cucaCustomNoteSelector(state),
  dayOptions: cucaDayOptionsSelector(state),
  overnightOptions: cucaOvernightOptionsSelector(state),
});

const mapDispatchToProps = {
  adminChange: adminActions.adminChange,
  resetAdmin: adminActions.resetAdmin,
};

const BACKEND_ID = 'cucamonga';
const WILDERNESS_TITLE = 'Cucamonga Wilderness';

export const CucamongaAdminContainer = ({
  adminChange,
  availableDayOptions,
  availableOvernightOptions,
  availableOvernightTrailheadOptions,
  customNote,
  dayOptions,
  overnightOptions,
}) => {
  const [customMessage, setCustomMessage] = useState(customNote);
  const [dayAvailabilityOptions, setDayAvailabilityOptions] = useState(availableDayOptions);
  const [trailheadAvailabilityOptions, setTrailheadAvailabilityOptions] = useState(availableOvernightTrailheadOptions);
  const [overnightAvailabilityOptions, setOvernightAvailabilityOptions] = useState(availableOvernightOptions);

  const handleCustomMessageChange = (event) => setCustomMessage(event.target.value);

  const handleOvernightAvailabilityChange = (optionId) => {
    const newArray = addRemoveElementFromArray(overnightAvailabilityOptions, optionId);
    return setOvernightAvailabilityOptions(newArray);
  };

  const handleDayAvailabilityChange = (optionId) => {
    const newArray = addRemoveElementFromArray(dayAvailabilityOptions, optionId);
    return setDayAvailabilityOptions(newArray);
  };

  const handleTrailheadAvailabilityChange = (optionId) => {
    const newArray = addRemoveElementFromArray(trailheadAvailabilityOptions, optionId);
    return setTrailheadAvailabilityOptions(newArray);
  };

  const handleSave = (event) => {
    event.preventDefault();

    const isCustomMessageChange = customMessage !== customNote;
    const isDayOptionsChange = !areArraysEqual(dayAvailabilityOptions, availableDayOptions);
    const isOvernightOptionsChange = !areArraysEqual(overnightAvailabilityOptions, availableOvernightOptions);
    const isTrailheadOptionsChange = !areArraysEqual(trailheadAvailabilityOptions, availableOvernightTrailheadOptions);

    const areThereAnyChanges =
      isCustomMessageChange || isDayOptionsChange || isOvernightOptionsChange || isTrailheadOptionsChange;

    if (!areThereAnyChanges) toast.error('There are no changes, please make a change in order to save');
    else {
      const data = {
        ...(isCustomMessageChange && { customNote: customMessage === '' ? null : customMessage }),
        ...(isDayOptionsChange && { availableDayOptions: dayAvailabilityOptions }),
        ...(isOvernightOptionsChange && { availableOvernightOptions: overnightAvailabilityOptions }),
        ...(isTrailheadOptionsChange && { availableOvernightTrailheads: trailheadAvailabilityOptions }),
      };

      adminChange({ data, locationType: BACKEND_ID });
    }
  };
  return (
    <Portal
      customMessage={customMessage}
      dayAvailabilityOptions={dayAvailabilityOptions}
      dayOptions={dayOptions}
      onCustomMessageChange={handleCustomMessageChange}
      onDayAvailabilityChange={handleDayAvailabilityChange}
      onOvernightAvailabilityChange={handleOvernightAvailabilityChange}
      onSave={handleSave}
      onTrailheadAvailabilityChange={handleTrailheadAvailabilityChange}
      overnightAvailabilityOptions={overnightAvailabilityOptions}
      overnightOptions={overnightOptions}
      trailheadAvailabilityOptions={trailheadAvailabilityOptions}
      title={WILDERNESS_TITLE}
    />
  );
};

CucamongaAdminContainer.propTypes = {
  adminChange: PropTypes.func.isRequired,
  availableDayOptions: PropTypes.arrayOf(PropTypes.any),
  availableOvernightOptions: PropTypes.arrayOf(PropTypes.any),
  availableOvernightTrailheadOptions: PropTypes.arrayOf(PropTypes.any),
  customNote: PropTypes.string,
  dayOptions: PropTypes.objectOf(PropTypes.any).isRequired,
  overnightOptions: PropTypes.objectOf(PropTypes.any).isRequired,
};

CucamongaAdminContainer.defaultProps = {
  availableDayOptions: [],
  availableOvernightOptions: [],
  availableOvernightTrailheadOptions: [],
  customNote: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(CucamongaAdminContainer);
