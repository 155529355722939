/* eslint-disable sort-keys */
import Moment from 'moment';

export const sortReservations = (reservations) => {
  reservations.sort((a, b) => {
    const isSameDate = a.startDate ? a.startDate === b.startDate : a.date === b.date;
    const isTrailSame = a.trail === b.trail;

    const compareName = a.name > b.name;
    const compareTrail = a.camp ? a.camp > b.camp : a.trail > b.trail;
    const compareDate = a.startDate ? a.startDate > b.startDate : a.date > b.date;

    if (isSameDate) {
      if (isTrailSame) return compareName ? 1 : -1;
      return compareTrail ? 1 : -1;
    }

    return compareDate ? 1 : -1;
  });
};

export const generateCounterData = (snapshots, csv) => {
  snapshots.forEach((doc) => {
    const docData = doc.data();
    const date = Moment(doc.id).format('MM-DD-YYYY');

    const data = Object.keys(docData).reduce((acc) => {
      acc.date = date;
      acc.totalReservation = docData.reservationCount;
      acc.totalCancellations = docData.cancelCount;

      return acc;
    }, {});

    csv.push(data);
  });

  const sortedData = csv.sort((a, b) => {
    const compareDate = a.date > b.date;
    return compareDate ? 1 : -1;
  });

  return sortedData;
};

const oldReservationSummary = (isFullSummary, key, docData, acc, permitId) => {
  if (key !== 'type') return acc;

  if (docData[key] === 'dayHikeId') {
    const date = Moment(docData.date.utc).format('MM-DD-YYYY');
    acc.date = date;
    acc.trail = docData.trail.title;
  } else {
    let startDate = null;
    let endDate = null;

    Object.keys(docData.date).forEach((trailKey) => {
      const { end, start } = docData.date[trailKey].dates;

      const momentStart = Moment(start);
      const momentEnd = Moment(end);

      if (!startDate) startDate = momentStart;
      if (!endDate) endDate = momentEnd;

      if (startDate && momentStart.isBefore(startDate)) startDate = momentStart;
      if (endDate && momentEnd.isAfter(endDate)) endDate = momentEnd;
    });

    const firstItem = startDate.format('MM-DD-YYYY');
    const lastItem = endDate.format('MM-DD-YYYY');

    const camps = Object.keys(docData.date).map((trailKey) => {
      const camp = docData.date[trailKey].trail.title;
      return camp;
    });

    acc.startDate = firstItem;
    acc.endDate = lastItem;
    acc.camps = camps.join(', ');
    acc.startingTrailhead = docData.startingTrailhead;
  }

  acc.permitId = permitId;
  acc.numberOfHikers = docData.groupNumber;
  acc.name = `${docData.lastName}, ${docData.firstName}`;
  acc.phoneNumber = docData.phoneNumber;

  if (isFullSummary) {
    acc.emailAddress = docData.email;

    acc.emergencyName = docData.emergencyName;
    acc.emergencyPhoneNumber = docData.emergencyNumber;

    acc.carLicenseNumber = docData.car.licenseNumber;
    acc.carMake = docData.car.make;
    acc.carModel = docData.car.model;
    acc.carRegisteredState = docData.car.state;

    acc.primaryAddress = docData.address.primaryAddress;
    acc.secondaryAddress = docData.address.secondaryAddress ? docData.address.secondaryAddress : 'N/A';
    acc.stateAddress = docData.address.state;
    acc.city = docData.address.city;
    acc.zipCode = docData.address.zipCode;

    acc.isReservationCancelled = docData.isCancelled ? 'Yes' : 'No';
    acc.isEmailSubscribed = docData.isEmailSignedUp ? 'Yes' : 'No';
  }

  return acc;
};

const newReservationSummary = (isFullSummary, key, docData, acc) => {
  if (key !== 'reservationType') return acc;

  if (docData[key] === 'dayId') {
    const date = Moment(docData.selection.date).format('MM-DD-YYYY');

    acc.date = date;
    acc.trail = docData.selection.title;
  } else {
    let startDate = null;
    let endDate = null;

    Object.keys(docData.selection).forEach((siteKey) => {
      const { end, start } = docData.selection[siteKey];

      const momentStart = Moment(start.date);
      const momentEnd = Moment(end.date);

      if (!startDate) startDate = momentStart;
      if (!endDate) endDate = momentEnd;

      if (startDate && momentStart.isBefore(startDate)) startDate = momentStart;
      if (endDate && momentEnd.isAfter(endDate)) endDate = momentEnd;
    });

    const sites = Object.keys(docData.selection).map((siteKey) => docData.selection[siteKey].start.title);

    acc.startDate = startDate.format('MM-DD-YYYY');
    acc.endDate = endDate.format('MM-DD-YYYY');
    acc.camps = sites.join(', ');
    acc.startingTrailhead = docData.selectedTrailhead.title;
  }

  acc.numberOfHikers = docData.user.groupNumber;
  acc.name = `${docData.user.lastName}, ${docData.user.firstName}`;
  acc.phoneNumber = docData.user.phoneNumber;
  acc.permitId = docData.permitNumber;

  if (isFullSummary) {
    acc.emailAddress = docData.email;

    acc.emergencyContactName = docData.user.emergencyName;
    acc.emergencyContactPhoneNumber = docData.user.emergencyNumber;

    acc.licenseNumber = docData.user.car.licenseNumber;
    acc.make = docData.user.car.make;
    acc.model = docData.user.car.model;
    acc.registeredState = docData.user.car.state;

    acc.primaryAddress = docData.user.address.primaryAddress;
    acc.secondaryAddress = docData.user.address.secondaryAddress ? docData.user.address.secondaryAddress : 'N/A';
    acc.stateAddress = docData.user.address.state;
    acc.city = docData.user.address.city;
    acc.zipCode = docData.user.address.zipCode;

    acc.isReservationCancelled = docData.isCancelled ? 'Yes' : 'No';
    acc.isEmailSubscribed = docData.isEmailSignedUp ? 'Yes' : 'No';
  }

  return acc;
};

export const getSummary = (isFullSummary, querySnapshot, reservationCSV) =>
  querySnapshot.forEach((doc) => {
    const docData = doc.data();

    const data = Object.keys(docData).reduce((acc, key) => {
      const userData = docData.user
        ? newReservationSummary(isFullSummary, key, docData, acc)
        : oldReservationSummary(isFullSummary, key, docData, acc, doc.id);

      return userData;
    }, {});

    reservationCSV.push(data);
  });
