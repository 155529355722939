import Moment from 'moment';
import { reservationMappings, DAY_ID } from 'constants/permitTypes';
import { locationTypeFromBackendTitles } from 'constants/locationTypes';

const generateEmail = (pdf, reservation, trailheadOptions) => {
  const { customNote, date, email, locationType, permitNumber, reservationType, selection, trail } = reservation;

  const isInteractiveMap = locationType === 'san_gorgonio';

  const reservationTypeTitle = reservationMappings[reservationType];
  const isDayReservationType = reservationType === DAY_ID;
  const locationTitle = locationTypeFromBackendTitles[locationType];

  let site;
  let firstName;
  let lastName;
  let groupNumber;
  let selectedTrailhead;

  if (reservation.user) {
    ({ firstName } = reservation.user);
    ({ lastName } = reservation.user);
    ({ groupNumber } = reservation.user);

    if (isDayReservationType) {
      site = `
        <p>Trail: ${selection.title}</p>
        <p>Date: ${selection.date}</p>
      `;
    } else {
      selectedTrailhead = reservation.selectedTrailhead.title;
      site = Object.keys(selection).map((siteId) => {
        const { end, start } = selection[siteId];

        return `
        <p>Campsite: ${start.title}</p>
        <p>Date: ${start.date} to ${end.date}</p>
      `;
      });
    }
  } else {
    // Old reservation object prior June 2021
    ({ firstName } = reservation);
    ({ lastName } = reservation);
    ({ groupNumber } = reservation);

    if (isDayReservationType) {
      site = `
        <p>Trail: ${trail.title}</p>
        <p>Date: ${date.formatDate}</p>
      `;
    } else {
      selectedTrailhead = trailheadOptions[reservation.startingTrailhead].title;
      site = Object.keys(date).map((siteId) => {
        const { dates } = date[siteId];

        const startDate = Moment(dates.start).format('YYYY-MM-DD');
        const endDate = Moment(dates.end).format('YYYY-MM-DD');

        return `
        <p>Campsite: ${date[siteId].trail.title}</p>
        <p>Date: ${startDate} to ${endDate}</p>
      `;
      });
    }
  }

  const siteContent = isDayReservationType ? site : site.join('');
  return {
    message: {
      attachments: [
        {
          filename: 'wilderness-permit.pdf',
          path: `${pdf}`,
        },
        {
          filename: 'trail-mileage.pdf',
          path: 'https://sgwa.dreamhosters.com/wp-content/uploads/2019/10/Mileage2018.pdf',
        },
      ],
      html: `
        <p>Hello ${firstName},</p>

        <p>We appreciate your decision to embark on an adventure in the captivating ${locationTitle}. Please find your official ${reservationTypeTitle} Permit attached to this email for your upcoming hike.</p>

        <p>To ensure a seamless experience, kindly have this permit accessible throughout your journey within the wilderness. In instances where your group may decide to split into smaller units, it is crucial for each group to carry a copy of this permit. You can either make physical copies or share the permit digitally in advance.</p>

        ${customNote ? `<p><b>Please note</b>: ${customNote}</p>` : ''}
        
        <p>
          <p>We strongly urge all adventurers to <b>equip themselves with a paper map</b> for their journey. We recommend the highly detailed Tom Harrison San Gorgonio Wilderness map which can be procured at our <a href="https://www.sgwa.org/store" target="_blank" rel="noopener noreferrer" clicktracking="off">store</a>. Because while digital apps may be tempting, their usage often presents challenges on the trail. Accuracy can be variable, and reliance on a cell signal or battery life can lead to unexpected issues. To avoid these pitfalls and ensure your trip runs smoothly, consider the invaluable benefits of a physical map:</p>
            <ul>
              <li><b>Unfailing Reliability:</b> Unlike technology, a paper map will never lose signal or run out of battery - it's your fail-safe companion.</li>
              <li><b>Comprehensive Overview:</b> Gain a broader perspective of your route and the surrounding terrain, helping you plan and anticipate the journey ahead.</li>
              <li><b>Device Preservation:</b> Using a physical map helps save the battery life of your device for emergency situations or capturing unforgettable moments.</li>
            </ul>
        </p>
        <p>As an additional reminder, always stick to the designated trails to protect the natural environment and preserve it for future adventurers.</p>

        <h4><b>Online Permit Number: ${permitNumber}<b/></h4>
        <p>Visitor: ${firstName} ${lastName}</p>
        <p>Group size: ${groupNumber}</p>
        ${selectedTrailhead ? `<p>Trailhead: ${selectedTrailhead.title}</p>` : ''}
        ${siteContent}
        ${
          isInteractiveMap
            ? `
              <h3>Trail Conditions</h3>
              <p>We encourage you to stay updated with the latest trail conditions, water sources and more by clicking the button below. After your adventure, we would appreciate it if you could share your experience and observations for the benefit of future hikers (login required).</p>
              <a style="display: inline-block; margin: 10px 0; padding: 12px 18px; font-size: 1rem; font-weight: 600; border-radius: 2px; color: #ffffff; border: 0; outline: none; background: #2B63F6; cursor: pointer; text-decoration: none;" title="Switchbacks - ${locationTitle}" href="https://switchbacks.app" target="_blank" rel="noopener noreferrer" clicktracking="off">Trail Conditions</a>
              `
            : ''
        }

        <h3>Cancellations</h3>
        <p>Should there be any changes in your plans that prevent you from proceeding with your trip, we kindly request that you cancel your reservation. This gesture will allow others the opportunity to secure a permit.</p>
        <a style="display: inline-block; margin: 10px 0; padding: 12px 18px; font-size: 1rem; font-weight: 600; border-radius: 2px; color: #ffffff; border: 0; outline: none; background: #2B63F6; cursor: pointer; text-decoration: none;" title="Switchbacks - ${locationTitle}" href="https://permits.sgwa.org/cancel" target="_blank" rel="noopener noreferrer" clicktracking="off">Cancel Permit</a>

        <h3>Donations</h3>
        <p>As a valued member of our community, your support plays a vital role in preserving the beauty of the ${locationTitle}. Your involvement and donations not only sustain our operations but also ensure the trails you love remain accessible and well-maintained.</p>
        <a style="display: inline-block; margin: 20px 0; padding: 12px 18px; font-size: 1rem; font-weight: 600; border-radius: 2px; color: #ffffff; border: 0; outline: none; background: #2B63F6; cursor: pointer; text-decoration: none;" title="Donate" href="https://www.paypal.com/donate/?hosted_button_id=SHVX6DCDG9LRA" target="_blank" rel="noopener noreferrer" clicktracking="off">Donate</a>

        <p>For further inquiries about permits, camping, regulations, or wood-cutting permits, feel free to visit the <a href="https://sgwa.org" target="_blank" rel="noopener noreferrer" clicktracking="off">${locationTitle} website</a> or get in touch with the Mill Creek Ranger Station at (909) 382-2882.</p>

        <p>Looking forward to your wilderness adventure!</p>
      `,
      subject: `${locationTitle} | ${reservationTypeTitle} Permit`,
    },
    to: `${email}`,
  };
};

export default generateEmail;
