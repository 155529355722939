import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'focus-visible';

import { Route, Router, Switch } from 'react-router-dom';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import history from 'lib/history';
import { isIESelector } from 'store/userAgent/selectors';
import { isAuthorizedUserSelector, initAppErrorSelector, isAppInitializingSelector } from 'store/app/selectors';

import LoadingSpinner from 'components/shared/LoadingSpinner';
import ValidatedRoute from 'components/shared/ValidatedRoute';

import Login from '../Login';
import NoIEView from './NoIEView';
import NoPageFound from './NoPageFound';
import Portal from '../Portal';

import { SubTitle, LoadingWrapper } from './style';

const mapStateToProps = (state) => ({
  error: initAppErrorSelector(state),
  isAuthorizedUser: isAuthorizedUserSelector(state),
  isIE: isIESelector(state),
  isLoading: isAppInitializingSelector(state),
});

export const App = ({ error, isAuthorizedUser, isIE, isLoading }) => {
  useEffect(() => {
    const { location } = history;

    if (isAuthorizedUser && location.pathname === '/login') history.push('/');
  }, [isAuthorizedUser]);

  useEffect(() => {
    if (error) toast.error(error);
  }, [error]);

  if (isIE) return <NoIEView />;
  if (isLoading) {
    return (
      <LoadingWrapper>
        <SubTitle>Loading Admin Portal...</SubTitle>
        <LoadingSpinner />
      </LoadingWrapper>
    );
  }

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <ValidatedRoute path="/" component={Portal} redirectPathname="/login" shouldRender={isAuthorizedUser} />
        <Route exact path="/" component={Portal} />
        <Route path="*" component={NoPageFound} />
      </Switch>
      <ToastContainer />
    </Router>
  );
};

App.propTypes = {
  error: PropTypes.string,
  isAuthorizedUser: PropTypes.bool.isRequired,
  isIE: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

App.defaultProps = {
  error: null,
};

export default connect(mapStateToProps)(App);
