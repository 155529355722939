import Debug from 'debug';
import { call, put, takeLatest } from 'redux-saga/effects';

import { sortReservations, getSummary } from './csvUtils';

import { firestore } from '../../../firebase';
import { DOWNLOAD_RESERVATIONS, downloadReservations } from '../actions';

const debug = Debug('sgwa:client-admin:store:downloads:sagas:downloadReservations');

export function* downloadReservationsSaga({ payload }) {
  const { isCancelledPermits, isFullSummary, locationType, selectedDates, type } = payload;
  const { end, start } = selectedDates;
  debug('Saga called', payload);

  try {
    const ref = firestore.doc(`${locationType}/main`).collection('reservations');

    const refQuery = ref
      .where('reservationType', '==', type)
      .where('queryDate', '>=', start)
      .where('queryDate', '<=', end)
      .where('isCancelled', '==', isCancelledPermits);

    const querySnapshot = yield call([refQuery, refQuery.get]);

    if (querySnapshot.empty) throw Error('Sorry! No reservations found for those dates. Please try again');
    debug('Reservations found', querySnapshot);

    const csv = [];

    getSummary(isFullSummary, querySnapshot, csv);
    sortReservations(csv);

    debug('Reservations CSV ready', csv);

    yield put(downloadReservations.success(csv));
  } catch (e) {
    debug('Saga error', e.message);
    yield put(downloadReservations.error(e.message));
  }
}

export default function* () {
  yield takeLatest(DOWNLOAD_RESERVATIONS.PENDING, downloadReservationsSaga);
}
