import styled from 'styled-components';
import { media } from 'styles';

export const SummaryWrapper = styled.div`
  position: relative;
  background-color: #2E5941;
  border: 1px solid #000000;
  border-radius: 4px;
  color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;

  ${media.min.mobileLandscape`min-width: 600px;`}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #2b4333;
`;

export const TypeTitle = styled.h2`
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0;

  ${media.twoDimMin.tablet`font-size: 1.8rem;`}
`;

export const PermitTitle = styled.h2`
  font-weight: 350;
  font-size: 1.6rem;
  margin: 0;

  ${media.twoDimMin.tablet`font-size: 1.8rem;`}
`;

export const Body = styled.div`
  padding: 20px;
  margin-bottom: 30px;
`;

export const Text = styled.p`
  margin: 0;
  padding-top: 18px;
  font-size: 1.6rem;
  font-weight: 700;

  ${media.twoDimMin.tablet`font-size: 1.8rem;`}
`;

export const SubText = styled.p`
  margin: 0;
  padding-top: 2px;
  font-size: 1.6rem;
  font-weight: 350;

  ${media.twoDimMin.tablet`font-size: 1.8rem;`}
`;
