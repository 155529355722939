import styled from 'styled-components';
import { media } from 'styles';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MessageWrapper = styled.div`
  max-width: 900px;
  padding: 64px 88px;
  font-size: 1.4rem;
  color: #fff;
`;

export const MessageHeader = styled.h3`
  font-weight: 700;
  color: #2e5941;
  font-size: 1.6rem;
`;

export const SubTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 30px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const LoadingWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

// No Found Page

export const NoPageFoundWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const NoPageFoundTitle = styled.h1`
  font-size: 2.2rem;
  font-weight: 500;
  color: #000;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const TextWrapper = styled.div`
  position: absolute;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
`;
