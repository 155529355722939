import React, { useState } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DateRangePicker } from 'react-dates';

export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

export const StartAndEndDatePicker = ({ onChange }) => {
  const [dates, setDates] = useState({});
  const [focusedInput, setFocusedInput] = useState(null);

  const handleDateChange = (selectedDates) => {
    setDates(selectedDates);

    const { endDate, startDate } = selectedDates;
    if (startDate && endDate) {
      onChange({
        end: Moment(endDate).endOf('day').toDate(),
        start: Moment(startDate).startOf('day').toDate(),
      });
    }
  };

  const handleFocusChange = (focused) => {
    setFocusedInput(focused);
  };

  const { endDate, startDate } = dates;
  return (
    <DateRangePicker
      enableOutsideDays
      endDate={endDate}
      endDateId="end-date-id"
      focusedInput={focusedInput}
      isOutsideRange={() => false}
      numberOfMonths={1}
      onDatesChange={handleDateChange}
      onFocusChange={handleFocusChange}
      startDate={startDate}
      minimumNights={0}
      startDateId="start-date-id"
    />
  );
};

StartAndEndDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default StartAndEndDatePicker;
