import React from 'react';
import PropTypes from 'prop-types';

import { Editor } from 'react-draft-wysiwyg';

import Button from 'components/shared/Button';
import StartAndEndDatePicker from 'components/shared/StartAndEndDatePicker';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import TypeAheadPrompt from 'components/shared/TypeAheadPrompt';

import 'static/css/react-draft-wysiwyg.css';

import {
  EmailWrapper,
  EditorWrapper,
  ButtonWrapper,
  InstructionText,
  LoadingWrapper,
  OptionWrapper,
  SubTitle,
  TextArea,
  TypeAheadWrapper,
} from './style';

const Email = ({
  editorState,
  isLoading,
  locationTypeOptions,
  onDateChange,
  onEditorChange,
  onLocationType,
  onSend,
  onSubjectLineChange,
}) => {
  let content;
  if (isLoading) {
    content = (
      <LoadingWrapper>
        <SubTitle>Sending...</SubTitle>
        <LoadingSpinner />
      </LoadingWrapper>
    );
  } else {
    content = (
      <EmailWrapper>
        <OptionWrapper>
          <InstructionText>Please start with selecting a Wilderness area first</InstructionText>
        </OptionWrapper>
        <TypeAheadWrapper>
          <TypeAheadPrompt
            options={locationTypeOptions}
            onChange={onLocationType}
            placeholder="Select a Wilderness first"
          />
        </TypeAheadWrapper>
        <OptionWrapper>
          <InstructionText>
            Next, select a start date and end date of the permit holders you want to send this email too.
          </InstructionText>
        </OptionWrapper>
        <StartAndEndDatePicker onChange={onDateChange} />
        <OptionWrapper>
          <InstructionText>Add your subject line.</InstructionText>
        </OptionWrapper>
        <TextArea rows="1" onChange={(event) => onSubjectLineChange(event.target.value)} />
        <OptionWrapper>
          <InstructionText>Add the body of your email</InstructionText>
        </OptionWrapper>
        <EditorWrapper className="editor">
          <Editor
            placeholder="Click here to start typing..."
            editorState={editorState}
            onEditorStateChange={onEditorChange}
            toolbar={{
              history: { inDropdown: true },
              inline: { inDropdown: true },
              link: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
            }}
          />
        </EditorWrapper>
        <OptionWrapper>
          <ButtonWrapper>
            <Button onClick={onSend}>Send</Button>
          </ButtonWrapper>
        </OptionWrapper>
      </EmailWrapper>
    );
  }

  return content;
};

Email.propTypes = {
  editorState: PropTypes.any.isRequired,
  isLoading: PropTypes.bool.isRequired,
  locationTypeOptions: PropTypes.objectOf(PropTypes.string).isRequired,
  onDateChange: PropTypes.func.isRequired,
  onEditorChange: PropTypes.func.isRequired,
  onLocationType: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onSubjectLineChange: PropTypes.func.isRequired,
};

export default Email;
