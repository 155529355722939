import React from 'react';
import PropTypes from 'prop-types';

import * as navMenuOptions from 'constants/navMenuOptions';
import Button from 'components/shared/Button';

import { ButtonWrapper, ContentWrapper, LayoutWrapper, NavMenuWrapper } from './style';

const PortalLayout = ({ children, isAdmin, onLogout, onNavChange }) => {
  let adminOptions = null;
  if (isAdmin) {
    adminOptions = (
      <>
        <ButtonWrapper>
          <Button isBasic onClick={() => onNavChange(navMenuOptions.SGW_ADMIN)}>
            San Gorgonio Admin
          </Button>
        </ButtonWrapper>
      </>
    );
  }
  return (
    <LayoutWrapper>
      <NavMenuWrapper>
        <ButtonWrapper>
          <Button isBasic onClick={() => onNavChange(navMenuOptions.RESERVATIONS)}>
            Reservations
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button isBasic onClick={() => onNavChange(navMenuOptions.DOWNLOADS)}>
            Downloads
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button isBasic onClick={() => onNavChange(navMenuOptions.CUCA_ADMIN)}>
            Cucamonga Admin
          </Button>
        </ButtonWrapper>
        {adminOptions}
        <ButtonWrapper>
          <Button isBasic onClick={() => onNavChange(navMenuOptions.PORTAL_OPTIONS)}>
            Portal Options
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button isBasic onClick={() => onNavChange(navMenuOptions.EMAIL)}>
            Email
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button variant="red" isBasic onClick={onLogout}>
            Logout
          </Button>
        </ButtonWrapper>
      </NavMenuWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </LayoutWrapper>
  );
};

PortalLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  onNavChange: PropTypes.func.isRequired,
};

export default PortalLayout;
