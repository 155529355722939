import styled from 'styled-components';
import { colors, type } from 'styles';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 36px;
  margin-top: 36px;
  width: 700px;
`;

export const Cell = styled.div``;

export const FieldTitle = styled.h2`
  font-size: 1.6rem;
  font-weight: ${type.weight.bold};
  color: ${colors.sierra.base};
  margin: 0 0 8px;

  ${(p) =>
    p.required &&
    `
    &:after {
      content: '*';
      margin-left: 4px;
      font-size: 1.8rem;
      font-weight: ${type.weight.bold};
      color: #2E5941;
    }
  `}
`;
