import styled from 'styled-components';
import { colors } from 'styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ToggleSwitchWrapper = styled.div`
  position: relative;
  width: 58px;
  height: 32px;
`;

export const DescriptionText = styled.div`
  margin-left: 32px;
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const Label = styled.label`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.rocky.dark};
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  ${Input}:checked + & {
    background-color: ${colors.pacific.base};
  }

  ${Input}:focus + & {
    box-shadow: 0 0 1px ${colors.pacific.base};
  }

  ${Input}:checked + &:before {
    transform: translateX(26px);
  }
`;
