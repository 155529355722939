import styled from 'styled-components';
import { media } from 'styles';

export const DownloadsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const InstructionText = styled.h3`
  font-size: 2.2rem;
  color: #626262;
  margin: 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const TypeAheadWrapper = styled.div`
  margin: 12px 0;
  width: 300px;
`;

export const OptionWrapper = styled.div`
  margin: 12px 0;
`;

export const SubTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 30px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Checkbox = styled.input`
  background-color: #2e5941;
  border: 1px solid #000000;
  border-radius: 3px;
  width: 22px;
  height: 22px;
`;

export const CheckboxLabel = styled.label`
  width: 100%;
  margin-left: 16px;
  color: #000000;
  font-weight: 500;
  font-size: 1.4rem;

  ${media.twoDimMin.tablet`font-size: 1.6rem;`}
`;

export const ListDivider = styled.div`
  border-bottom: 1px solid rgba(111, 125, 141, 0.2);
  margin: 14px 0;
`;
