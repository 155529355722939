import styled from 'styled-components';
import { media } from 'styles';

const smIconSize = 20;
const mdIconSize = 24;

export const CheckboxWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
`;

export const Label = styled.label`
  cursor: pointer;
  display: inline-block;
  padding-left: ${smIconSize + smIconSize / 2}px;
  vertical-align: middle;
  user-select: none;

  ${media.min.baseline`
    padding-left: ${mdIconSize + mdIconSize / 2}px;
  `}

  &:before {
    -webkit-box-sizing: border-box;
    border: 1px solid #faeede;
    box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.12);
    content: '';
    display: inline-block;
    position: absolute;
    top: -1px;
    left: 0;
    width: ${smIconSize}px;
    height: ${smIconSize}px;

    ${media.min.baseline`
      width: ${mdIconSize}px;
      height: ${mdIconSize}px;
    `}
  }

  ${Input}:checked ~ &:after {
    -webkit-box-sizing: border-box;
    color: #2e5941;
    content: '✔';
    display: block;
    position: absolute;
    top: 30%;
    left: ${smIconSize / 2}px;
    margin-left: -${smIconSize / 4}px;
    margin-top: -${smIconSize / 4}px;
    width: ${smIconSize / 2}px;
    height: ${smIconSize / 2}px;

    ${media.min.baseline`
      left: ${mdIconSize / 2}px;
      margin-left: -${mdIconSize / 4}px;
      margin-top: -${mdIconSize / 4}px;
      width: ${mdIconSize / 2}px;
      height: ${mdIconSize / 2}px;
    `}
  }
`;
