import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/shared/Button';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import Checkbox from 'components/shared/Checkbox';

import {
  ButtonWrapper,
  CheckboxWrapper,
  InstructionText,
  LoadingWrapper,
  OptionWrapper,
  PortalWrapper,
  SubTitle,
  Title,
  TextArea,
} from './style';

const Portal = ({
  customMessage,
  dayAvailabilityOptions,
  dayOptions,
  isLoading,
  onCustomMessageChange,
  onDayAvailabilityChange,
  onOvernightAvailabilityChange,
  onSave,
  onTrailheadAvailabilityChange,
  overnightAvailabilityOptions,
  overnightOptions,
  title,
  trailheadAvailabilityOptions,
}) => {
  let content;
  if (isLoading) {
    content = (
      <LoadingWrapper>
        <SubTitle>Updating...</SubTitle>
        <LoadingSpinner />
      </LoadingWrapper>
    );
  } else {
    content = (
      <PortalWrapper>
        <Title>{title} Admin</Title>
        <OptionWrapper>
          <InstructionText>
            This is a custom message that will be added to only {title} permits and emails when users make a reservation
          </InstructionText>
          <TextArea value={customMessage || ''} onChange={onCustomMessageChange} />
        </OptionWrapper>
        <OptionWrapper>
          <InstructionText>Overnight availability options. Check the box to enable or disable a site</InstructionText>
          <CheckboxWrapper>
            {Object.keys(overnightOptions).map((siteKey) => {
              const overnightSite = overnightOptions[siteKey];

              const isChecked = overnightAvailabilityOptions.includes(overnightSite.id);
              return (
                <Checkbox
                  key={overnightSite.id}
                  id={overnightSite.id}
                  isChecked={isChecked}
                  onChange={() => onOvernightAvailabilityChange(overnightSite.id)}
                >
                  {overnightSite.title}
                </Checkbox>
              );
            })}
          </CheckboxWrapper>
        </OptionWrapper>
        <OptionWrapper>
          <InstructionText>
            Trailhead availability options for overnight permits. Check the box to enable or disable a site. There needs
            to be at least one enabled.
          </InstructionText>
          <CheckboxWrapper>
            {Object.keys(dayOptions).map((siteKey) => {
              const daySite = dayOptions[siteKey];

              const isChecked = trailheadAvailabilityOptions.includes(daySite.id);
              return (
                <Checkbox
                  key={`${daySite.id}-trail`}
                  id={`${daySite.id}-trail`}
                  isChecked={isChecked}
                  onChange={() => onTrailheadAvailabilityChange(daySite.id)}
                >
                  {daySite.title}
                </Checkbox>
              );
            })}
          </CheckboxWrapper>
        </OptionWrapper>
        <OptionWrapper>
          <InstructionText>Day availability options. Check the box to enable or disable a site</InstructionText>
          <CheckboxWrapper>
            {Object.keys(dayOptions).map((siteKey) => {
              const daySite = dayOptions[siteKey];

              const isChecked = dayAvailabilityOptions.includes(daySite.id);
              return (
                <Checkbox
                  key={`${daySite.id}-day`}
                  id={`${daySite.id}-day`}
                  isChecked={isChecked}
                  onChange={() => onDayAvailabilityChange(daySite.id)}
                >
                  {daySite.title}
                </Checkbox>
              );
            })}
          </CheckboxWrapper>
        </OptionWrapper>
        <OptionWrapper>
          <ButtonWrapper>
            <Button onClick={onSave}>Save Changes</Button>
          </ButtonWrapper>
        </OptionWrapper>
      </PortalWrapper>
    );
  }

  return content;
};

Portal.propTypes = {
  customMessage: PropTypes.any.isRequired,
  dayAvailabilityOptions: PropTypes.any.isRequired,
  dayOptions: PropTypes.any.isRequired,
  isLoading: PropTypes.any.isRequired,
  onCustomMessageChange: PropTypes.any.isRequired,
  onDayAvailabilityChange: PropTypes.any.isRequired,
  onOvernightAvailabilityChange: PropTypes.any.isRequired,
  onSave: PropTypes.any.isRequired,
  onTrailheadAvailabilityChange: PropTypes.any.isRequired,
  overnightAvailabilityOptions: PropTypes.any.isRequired,
  overnightOptions: PropTypes.any.isRequired,
  trailheadAvailabilityOptions: PropTypes.any.isRequired,
};

export default Portal;
