import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import csvDownload from 'json-to-csv-export';
import { toast } from 'react-toastify';

import * as downloadActions from 'store/downloads/actions';
import { isDownloadingSelector, errorSelector, csvSelector } from 'store/downloads/selectors';
import { isAdminUserSelector } from 'store/app/selectors';

import Downloads from './Downloads';

const mapStateToProps = (state) => ({
  csv: csvSelector(state),
  error: errorSelector(state),
  isAdminUser: isAdminUserSelector(state),
  isDownloading: isDownloadingSelector(state),
});

const mapDispatchToProps = {
  downloadCounterData: downloadActions.downloadCounterData,
  downloadReservations: downloadActions.downloadReservations,
  resetDownload: downloadActions.resetDownload,
};

const adminLocationTypeOptions = {
  cucamonga: {
    id: 'cucamonga',
    title: 'Cucamonga Wilderness',
  },
  san_gorgonio: {
    id: 'san_gorgonio',
    title: 'San Gorgonio Wilderness',
  },
};

const cucamongaLocationTypeOptions = {
  cucamonga: {
    id: 'cucamonga',
    title: 'Cucamonga Wilderness',
  },
};

export const DownloadsContainer = ({
  csv,
  downloadCounterData,
  downloadReservations,
  error,
  isAdminUser,
  isDownloading,
  resetDownload,
}) => {
  const [dates, setDates] = useState({});
  const [counterDates, setCounterDates] = useState({});
  const [type, setType] = useState(null);
  const [locationType, setLocationType] = useState(null);
  const [isCancelledPermits, setIsCancelledPermits] = useState(false);
  const [isFullSummary, setIsFullSummary] = useState(false);

  useEffect(() => {
    if (error) toast.error(error);
  }, [error]);

  useEffect(() => {
    if (csv) {
      csvDownload(csv, 'sgwa-download.csv');
      resetDownload();
    }
  }, [csv]);

  useEffect(() => () => resetDownload(), []);

  const handleDownloadReservations = (event) => {
    event.preventDefault();

    if (!Object.keys(dates).length)
      return toast.error('Please select a start and end date from the calendar input field');
    if (!type) return toast.error('Please select a permit type from the dropdown menu');
    if (!locationType) return toast.error('Please select a location from the dropdown menu');

    return downloadReservations({
      ...dates,
      isCancelledPermits,
      isFullSummary,
      locationType: locationType.id,
      type: type.id,
    });
  };

  const handleDownloadCounterData = (event) => {
    event.preventDefault();

    if (!Object.keys(counterDates).length) {
      toast.error('Please select a start and end date from the calendar input field');
    } else if (!locationType) toast.error('Please select a location from the dropdown menu');
    else downloadCounterData({ counterDates, locationType: locationType.id });
  };

  const handleCounterDatesSelected = (selectedDates) =>
    setCounterDates((prevDates) => ({ ...prevDates, selectedDates }));

  const handleDatesSelected = (selectedDates) => setDates((prevDates) => ({ ...prevDates, selectedDates }));
  const locationTypeOptions = isAdminUser ? adminLocationTypeOptions : cucamongaLocationTypeOptions;
  return (
    <Downloads
      isCancelledPermits={isCancelledPermits}
      isDownloadButtonDisabled={!Object.keys(dates).length || !type}
      isDownloading={isDownloading}
      isFullSummary={isFullSummary}
      locationTypeOptions={locationTypeOptions}
      onCancelledPermits={() => setIsCancelledPermits((p) => !p)}
      onCounterDatesSelected={handleCounterDatesSelected}
      onDatesSelected={handleDatesSelected}
      onDownloadCounterData={handleDownloadCounterData}
      onDownloadReservations={handleDownloadReservations}
      onFullSummary={() => setIsFullSummary((p) => !p)}
      onLocationType={(selectedLocationType) => setLocationType(selectedLocationType)}
      onSelectedType={(selectedType) => setType(selectedType)}
    />
  );
};

DownloadsContainer.propTypes = {
  csv: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  downloadCounterData: PropTypes.func.isRequired,
  downloadReservations: PropTypes.func.isRequired,
  error: PropTypes.string,
  isAdminUser: PropTypes.bool.isRequired,
  isDownloading: PropTypes.bool.isRequired,
  resetDownload: PropTypes.func.isRequired,
};

DownloadsContainer.defaultProps = {
  csv: null,
  error: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadsContainer);
