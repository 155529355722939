import React from 'react';
import PropTypes from 'prop-types';
import FocusTrapReact from 'focus-trap-react';

const FocusTrap = ({
  children,
  clickOutsideDeactivates,
}) => (
  <FocusTrapReact
    focusTrapOptions={{ clickOutsideDeactivates }}
  >
    {children}
  </FocusTrapReact>
);

FocusTrap.propTypes = {
  children: PropTypes.node.isRequired,
  clickOutsideDeactivates: PropTypes.bool,
};

FocusTrap.defaultProps = {
  clickOutsideDeactivates: true,
};

export default FocusTrap;
