import Debug from 'debug';
import { customAlphabet } from 'nanoid';

import { call, select, put, takeLatest } from 'redux-saga/effects';

import generateEmail from 'lib/generateEmail';
import awaitAsyncAction from 'lib/awaitAsyncAction';

import { sgwCustomNoteSelector, cucaCustomNoteSelector } from 'store/app/selectors';
import { cucaDayOptionsSelector, sgwDayOptionsSelector } from 'store/reservations/selectors';

import { GENERATE_PERMIT, generatePermit, RESEND_EMAIL, resendEmail } from '../actions';

import { firestore } from '../../../firebase';

const debug = Debug('sgwa:client-admin:store:reservations:sagas:resendEmail');

export function* resendEmailSaga({ payload }) {
  const { permitNumber, reservation } = payload;
  debug('Saga called', payload);

  try {
    let customNote;
    let trailheadOptions;

    if (reservation.locationType === 'cucamonga') {
      customNote = yield select(cucaCustomNoteSelector);
      trailheadOptions = yield select(cucaDayOptionsSelector);
    } else {
      customNote = yield select(sgwCustomNoteSelector);
      trailheadOptions = yield select(sgwDayOptionsSelector);
    }

    const userPayload = {
      ...reservation,
      customNote,
      permitNumber,
    };

    yield put(generatePermit(userPayload));
    const [success, error] = yield call(awaitAsyncAction, GENERATE_PERMIT);

    if (error) throw error.payload;

    const emailPDF = `data:application/pdf;base64,${success.payload}`;
    const data = generateEmail(emailPDF, userPayload, trailheadOptions);

    const nanoid = customAlphabet('0123456789ABCDEFGJKLMNOPQRSTUVWXYZ', 6);
    const id = nanoid();

    const ref = firestore.collection('mail').doc(`${userPayload.email}-${userPayload.permitNumber}-${id}`);
    yield call([ref, ref.set], data, { merge: true });

    yield put(resendEmail.success());
  } catch (e) {
    debug('Saga error', e.message);
    yield put(resendEmail.error(e.message));
  }
}

export default function* () {
  yield takeLatest(RESEND_EMAIL.PENDING, resendEmailSaga);
}
