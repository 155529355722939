import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import * as navMenuOptions from 'constants/navMenuOptions';

import * as adminActions from 'store/admin/actions';

import SanGorgonioAdmin from './SanGorgonioAdmin';
import CucamongaAdmin from './CucamongaAdmin';

const mapDispatchToProps = {
  resetAdmin: adminActions.resetAdmin,
};

const componentMapping = {
  [navMenuOptions.SGW_ADMIN]: SanGorgonioAdmin,
  [navMenuOptions.CUCA_ADMIN]: CucamongaAdmin,
};

export const AdminContainer = ({ resetAdmin, selectedComponent }) => {
  useEffect(() => () => resetAdmin(), []);

  const AdminComponent = componentMapping[selectedComponent];
  return <AdminComponent />;
};

AdminContainer.propTypes = {
  resetAdmin: PropTypes.func.isRequired,
  selectedComponent: PropTypes.string.isRequired,
};

export default connect(null, mapDispatchToProps)(AdminContainer);
