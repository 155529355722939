import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import customDropdownStyles from './customDropdownStyles';

const Dropdown = ({ onChange, options, placeholder }) => (
  <Select
    onChange={(option) => onChange({ id: option.value, title: option.label })}
    options={options}
    styles={customDropdownStyles}
    placeholder={placeholder}
  />
);

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string,
};

Dropdown.defaultProps = {
  placeholder: 'Type or select...',
};

export default Dropdown;
