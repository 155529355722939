import React from 'react';
import track from 'react-tracking';

import history from 'lib/history';

import ButtonLink from 'components/shared/LinkButton';

import { NoPageFoundWrapper, NoPageFoundTitle, TextWrapper } from './style';

const NoPageFound = () => {
  const goToHomeButtonClick = () => {
    history.push('/');
  };

  return (
    <NoPageFoundWrapper>
      <TextWrapper>
        <NoPageFoundTitle>Looks like you&apos;re off trail</NoPageFoundTitle>
        <ButtonLink isBiggerText onClick={goToHomeButtonClick}>
          Go to Home
        </ButtonLink>
      </TextWrapper>
    </NoPageFoundWrapper>
  );
};

export default track({ module: 'NoPageFound' }, { dispatchOnMount: () => ({ event: 'No Page Found' }) })(NoPageFound);
