import styled from 'styled-components';
import { colors, type, media } from 'styles';
import { TransparentButton } from 'styles/components';

export const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
`;

export const AsteriskLegend = styled.span`
  color: ${colors.sierra.base};
  font-size: 1.4rem;
  font-weight: ${type.weight.semiBold};

  &:before {
    content: '*';
    font-size: 1.8rem;
    font-weight: ${type.weight.bold};
    color: #2e5941;
    margin-right: 4px;
  }
`;

export const CancelButtonWrapper = styled.div`
  display: inline-block;
  margin-right: 44px;
`;

export const LoadingText = styled.h3`
  font-size: 2.2rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 30px 0;
  text-align: center;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const LoadingWrapper = styled.div`
  margin-top: 36px;
  width: 700px;
  height: 400px;
`;
