import React from 'react';
import PropTypes from 'prop-types';

import {
  ContentWrapper,
  LayoutWrapper,
} from './style';

const Layout = ({
  children,
}) => (
  <LayoutWrapper>
    <ContentWrapper>
      {children}
    </ContentWrapper>
  </LayoutWrapper>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
