import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ADDRESS, CAR } from 'constants/reservationFormFields';

import * as reservationActions from 'store/reservations/actions';
import { isUpdatingReservation } from 'store/reservations/selectors';

import EditReservationModal from './EditReservationModal';

const mapStateToProps = (state) => ({
  isUpdating: isUpdatingReservation(state),
});

const mapDispatchToProps = {
  editReservation: reservationActions.editReservation,
};

const toReservation = (reservation) => {
  let modifiedReservation;

  if (!reservation.user) {
    modifiedReservation = {
      ...reservation,
      user: {
        address: {
          city: reservation.address.city,
          primaryAddress: reservation.address.primaryAddress,
          state: reservation.address.state,
          zipCode: reservation.address.zipCode,
        },
        car: {
          licenseNumber: reservation.car.licenseNumber,
          make: reservation.car.make,
          model: reservation.car.model,
          state: reservation.car.state,
        },
        emergencyName: reservation.emergencyName,
        emergencyNumber: reservation.emergencyNumber,
        firstName: reservation.firstName,
        groupNumber: reservation.groupNumber,
        lastName: reservation.lastName,
        phoneNumber: reservation.phoneNumber,
      },
    };
  } else modifiedReservation = reservation;

  return modifiedReservation;
};

const fromReservation = (reservation) => {
  let modifiedReservation;

  if (reservation.address) {
    const { user } = reservation;

    modifiedReservation = {
      ...reservation,
      ...user,
    };

    delete modifiedReservation.user;
  } else modifiedReservation = reservation;

  return modifiedReservation;
};

export const EditReservationModalContainer = ({ editReservation, isUpdating, onClose, permitId, reservation }) => {
  const initialReservation = useMemo(() => toReservation(reservation), [reservation]);
  const [reservationFields, setReservationFields] = useState(initialReservation);

  const handleChange = (name, value, nestedName = null) => {
    if (nestedName === ADDRESS || nestedName === CAR) {
      setReservationFields((prevFields) => {
        const nestedUser = prevFields.user[nestedName];
        return {
          ...prevFields,
          user: {
            ...prevFields.user,
            [nestedName]: {
              ...nestedUser,
              [name]: value,
            },
          },
        };
      });
    } else if (nestedName) {
      setReservationFields({
        ...reservationFields,
        [nestedName]: {
          ...reservationFields[nestedName],
          [name]: value,
        },
      });
    } else {
      setReservationFields({
        ...reservationFields,
        [name]: value,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const editedReservation = fromReservation(reservationFields);

    editReservation({
      editedReservation,
      locationType: reservation.locationType,
      permitNumber: permitId,
    });
  };

  return (
    <EditReservationModal
      isUpdating={isUpdating}
      onChange={handleChange}
      onClose={onClose}
      onSubmit={handleSubmit}
      fields={reservationFields}
    />
  );
};

EditReservationModalContainer.propTypes = {
  editReservation: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reservation: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditReservationModalContainer);
