import Debug from 'debug';
import { call, put, takeLatest } from 'redux-saga/effects';

import { firestore } from '../../../firebase';
import { FETCH_MAIN_COLLECTION, fetchMainCollection } from '../actions';

const debug = Debug('sgwa:client-admin:store:app:sagas:fetchMainCollection');

export function* fetchMainCollectionSaga({ payload: userClaims }) {
  debug('Saga called', userClaims);

  try {
    let sanGorgonioData;

    if (userClaims.isAdmin) {
      const sgRef = firestore.collection('san_gorgonio');
      const sanGorgonioSnapshot = yield call([sgRef, sgRef.get]);

      sanGorgonioData = sanGorgonioSnapshot.docs.reduce((acc, doc) => {
        const documentData = doc.data();
        acc[doc.id] = { ...documentData };

        return acc;
      }, {});

      debug('San Gorgonio data', sanGorgonioData);
    }

    const cRef = firestore.collection('cucamonga');
    const cucamongaSnapshot = yield call([cRef, cRef.get]);

    const cucamongaData = cucamongaSnapshot.docs.reduce((acc, doc) => {
      const documentData = doc.data();
      acc[doc.id] = { ...documentData };

      return acc;
    }, {});

    debug('Cucamonga data', cucamongaData);

    const portalRef = firestore.collection('portal').doc('data');
    const portalSnapshot = yield call([portalRef, portalRef.get]);

    const portalData = portalSnapshot.data();

    debug('Portal data', portalData);

    yield put(fetchMainCollection.success({ cucamongaData, portalData, sanGorgonioData }));
  } catch (e) {
    debug('Saga error', e);
    yield put(fetchMainCollection.error(e));
  }
}

export default function* () {
  yield takeLatest(FETCH_MAIN_COLLECTION.PENDING, fetchMainCollectionSaga);
}
