import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Moment from 'moment';

import * as adminActions from 'store/admin/actions';

import { errorSelector, isLoadingSelector } from 'store/admin/selectors';
import {
  isAdminUserSelector,
  isCucamongaEnabledSelector,
  isPortalUnderMaintenanceSelector,
  isSanGorgonioEnabledSelector,
  startDateSelector,
} from 'store/app/selectors';

import PortalOptions from './PortalOptions';

const mapStateToProps = (state) => ({
  error: errorSelector(state),
  isAdminUser: isAdminUserSelector(state),
  isCucamongaWildernessEnabled: isCucamongaEnabledSelector(state),
  isLoading: isLoadingSelector(state),
  isPortalUnderMaintenance: isPortalUnderMaintenanceSelector(state),
  isSanGorgonioWildernessEnabled: isSanGorgonioEnabledSelector(state),
  selectedStartDate: startDateSelector(state),
});

const mapDispatchToProps = {
  portalChange: adminActions.portalChange,
  resetAdmin: adminActions.resetAdmin,
};

export const PortalOptionsContainer = ({
  error,
  isAdminUser,
  isCucamongaWildernessEnabled,
  isLoading,
  isPortalUnderMaintenance,
  isSanGorgonioWildernessEnabled,
  portalChange,
  resetAdmin,
  selectedStartDate,
}) => {
  const [isCucamongaEnabled, setIsCucamongaEnabled] = useState(isCucamongaWildernessEnabled);
  const [isSanGorgonioEnabled, setIsSanGorgonioEnabled] = useState(isSanGorgonioWildernessEnabled);
  const [isPortalMaintenance, setIsPortalMaintenance] = useState(isPortalUnderMaintenance);
  const [startDate, setStartDate] = useState(Moment(`${selectedStartDate}`, 'MM-DD-YYYY'));
  const [isCalendarFocused, setIsCalendarFocused] = useState(false);

  useEffect(() => () => resetAdmin(), []);

  useEffect(() => {
    if (error) toast.error(error);
  }, [error]);

  const handleCucamongaToggle = () => setIsCucamongaEnabled((prevIsEnabled) => !prevIsEnabled);
  const handleSanGorgonioToggle = () => setIsSanGorgonioEnabled((prevIsEnabled) => !prevIsEnabled);
  const handleMaintenanceToggle = () => setIsPortalMaintenance((prevIsEnabled) => !prevIsEnabled);
  const handleStartDate = (date) => setStartDate(date);

  const handleSave = (event) => {
    event.preventDefault();

    const isCucamongaWildernessChange = isCucamongaEnabled !== isCucamongaWildernessEnabled;
    const isSanGorgonioWildernessChange = isSanGorgonioEnabled !== isSanGorgonioWildernessEnabled;
    const isPortalMaintenanceChange = isPortalMaintenance !== isPortalUnderMaintenance;
    const isStartDateChanged = startDate.format('MM-DD-YYYY') !== selectedStartDate;

    const areThereAnyChanges =
      isStartDateChanged || isPortalMaintenanceChange || isCucamongaWildernessChange || isSanGorgonioWildernessChange;

    if (!areThereAnyChanges) toast.error('There are no changes, please make a change in order to save');
    else {
      const data = {
        ...(isCucamongaWildernessChange && { isCucamongaEnabled }),
        ...(isSanGorgonioWildernessChange && { isSanGorgonioEnabled }),
        ...(isPortalMaintenanceChange && { isPortalMaintenance }),
        ...(isStartDateChanged && { startDate: startDate.format('MM-DD-YYYY') }),
      };

      portalChange(data);
    }
  };

  console.log('start', startDate);
  console.log('selectedStartDate', selectedStartDate);

  return (
    <PortalOptions
      isAdminUser={isAdminUser}
      isCalendarFocused={isCalendarFocused}
      isCucamongaEnabled={isCucamongaEnabled}
      isLoading={isLoading}
      isPortalMaintenance={isPortalMaintenance}
      isSanGorgonioEnabled={isSanGorgonioEnabled}
      onCalendarFocused={() => setIsCalendarFocused((prevIsCalFocused) => !prevIsCalFocused)}
      onCucamongaToggle={handleCucamongaToggle}
      onMaintenanceToggle={handleMaintenanceToggle}
      onSanGorgonioToggle={handleSanGorgonioToggle}
      onSave={handleSave}
      onStartDate={handleStartDate}
      startDate={startDate}
    />
  );
};

PortalOptionsContainer.propTypes = {
  error: PropTypes.string,
  isAdminUser: PropTypes.bool.isRequired,
  isCucamongaWildernessEnabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPortalUnderMaintenance: PropTypes.bool.isRequired,
  isSanGorgonioWildernessEnabled: PropTypes.bool.isRequired,
  portalChange: PropTypes.func.isRequired,
  resetAdmin: PropTypes.func.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
};

PortalOptionsContainer.defaultProps = {
  error: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(PortalOptionsContainer);
