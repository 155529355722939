import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track, { TrackingPropType } from 'react-tracking';

import * as appActions from 'store/app/actions';

import Button from 'components/shared/Button';
import Layout from 'components/shared/Layout';

import { ButtonWrapper, TextFieldInput, TextFieldWrapper, Title } from './style';

const mapDispatchToProps = {
  login: appActions.loginUser,
};

export const Login = ({ login, tracking: { trackEvent } }) => {
  const field = useRef();
  const [values, setValues] = useState({ email: null, password: null });

  useEffect(() => {
    field.current.focus();
  }, []);

  const handleChange = (name, value) =>
    setValues({
      ...values,
      [name]: value,
    });

  const handleSubmit = (event) => {
    event.preventDefault();
    login(values);

    trackEvent({ event: 'Submit Button Clicked' });
  };

  const areAnyFieldsIncomplete = Object.keys(values).some((key) => !values[key]);
  return (
    <Layout>
      <Title>Please enter your login credentials</Title>
      <form>
        <TextFieldWrapper>
          <TextFieldInput
            id="email"
            name="email"
            onChange={(event) => handleChange(event.target.id, event.target.value)}
            placeholder="Email"
            required
            type="text"
            value={values.email || ''}
            ref={field}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextFieldInput
            id="password"
            name="password"
            onChange={(event) => handleChange(event.target.id, event.target.value)}
            placeholder="Password"
            required
            type="password"
            value={values.password || ''}
          />
          <ButtonWrapper>
            <Button type="submit" disabled={areAnyFieldsIncomplete} onClick={handleSubmit}>
              Login
            </Button>
          </ButtonWrapper>
        </TextFieldWrapper>
      </form>
    </Layout>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  tracking: TrackingPropType,
};

Login.defaultProps = {
  tracking: null,
};

const trackedLogin = track({
  page: 'Login',
})(Login);

export default connect(null, mapDispatchToProps)(trackedLogin);
