import { fork, all } from 'redux-saga/effects';

import loggerSaga from 'lib/loggerSaga';
import appSagas from './app/sagas';
import reservationsSagas from './reservations/sagas';
import adminSagas from './admin/sagas';
import emailSagas from './email/sagas';
import downloadsSagas from './downloads/sagas';

const sagas = [loggerSaga, ...adminSagas, ...downloadsSagas, ...emailSagas, ...appSagas, ...reservationsSagas];

export default function* rootSaga() {
  yield all(sagas.map((saga) => fork(saga)));
}
