import {
  number,
  shape,
  string,
} from 'prop-types';

export const errorPropType = shape({
  message: string,
  status: number,
});

export const textFieldThemePropType = shape({
  container: shape({
    background: string,
    border: shape({
      default: string,
      error: string,
    }),
  }),
  input: shape({
    color: shape({
      default: string,
    }),
  }),
  label: shape({
    color: shape({
      default: string,
      error: string,
    }),
  }),
});
