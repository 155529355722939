import React from 'react';
import track from 'react-tracking';

import { MessageHeader, MessageWrapper, Wrapper } from './style';

export const NoIEView = () => (
  <Wrapper>
    <MessageWrapper>
      <MessageHeader>
        We apologize for the inconvenience, but we currently do not support Internet Explorer.
      </MessageHeader>
      Please try using either a different browser (e.g. Safari, Edge, Chrome).
    </MessageWrapper>
  </Wrapper>
);

export default track(
  { module: 'NoIEView' },
  { dispatchOnMount: () => ({ event: 'IE 11 View Shown' }) }
)(NoIEView);
