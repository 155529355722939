import { createAction, createAsyncAction, createAsyncTypes } from 'lib/reduxUtils';

// Sync
export const RESET_DOWNLOAD = 'downloads/resetDownload';
export const resetDownload = createAction(RESET_DOWNLOAD);

// Async
export const DOWNLOAD_RESERVATIONS = createAsyncTypes('downloads/downloadReservations');
export const downloadReservations = createAsyncAction(DOWNLOAD_RESERVATIONS);

export const DOWNLOAD_COUNTER_DATA = createAsyncTypes('downloads/downloadCounterData');
export const downloadCounterData = createAsyncAction(DOWNLOAD_COUNTER_DATA);
