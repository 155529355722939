import styled from 'styled-components';
import { media } from 'styles';

export const Title = styled.h1`
  font-size: 2.2rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 6px 0;
  align-self: flex-start;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const SubTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 30px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const TextFieldWrapper = styled.div`
  border-bottom: 1px solid #2e5941;
  width: 100%;
  position: relative;
  margin-top: 20px;

  ${media.min.mobileLandscape`width: 500px;`}
`;

export const TextFieldInput = styled.input`
  width: 100%;
  background-color: transparent;
  border: 0;
  color: #2e5941;
  outline: 0;
  box-shadow: none;
  font-size: 2.2rem;
  font-weight: 500;
  padding: 12px 12px 12px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}

  &::placeholder {
    color: #d0d0d0;
    font-weight: 400;
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  display: inline-block;
  bottom: -60px;
  right: 0;
`;
