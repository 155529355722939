import { createAction, createAsyncAction, createAsyncTypes } from 'lib/reduxUtils';

export const RESET_ADMIN = 'admin/resetAdmin';
export const resetAdmin = createAction(RESET_ADMIN);

// Async
export const ADMIN_CHANGE = createAsyncTypes('admin/adminChange');
export const adminChange = createAsyncAction(ADMIN_CHANGE);

export const PORTAL_CHANGE = createAsyncTypes('admin/portalChange');
export const portalChange = createAsyncAction(PORTAL_CHANGE);
