import { combineReducers } from 'redux';

import app from './app/reducer';
import userAgent from './userAgent/reducer';
import reservations from './reservations/reducer';
import admin from './admin/reducer';
import email from './email/reducer';
import downloads from './downloads/reducer';

export default combineReducers({
  admin,
  app,
  downloads,
  email,
  reservations,
  userAgent,
});
