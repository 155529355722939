import Debug from 'debug';
import { call, put, takeLatest } from 'redux-saga/effects';
import { customAlphabet } from 'nanoid';

import { firestore } from '../../../firebase';
import { SEND_EMAIL, sendEmail } from '../actions';

const debug = Debug('sgwa:client-admin:store:email:sagas:sendEmail');

const generateEmail = (email, html, subjectLine) => ({
  message: {
    html: `${html}`,
    subject: `${subjectLine}`,
  },
  to: `${email}`,
});

export function* sendEmailSaga({ payload }) {
  debug('Saga called', payload);

  const { dates, html, locationType, subjectLine } = payload;
  const { end, start } = dates;

  try {
    const ref = firestore.doc(`${locationType}/main`).collection('reservations');
    const emailQuery = ref
      .where('queryDate', '>=', start)
      .where('queryDate', '<=', end)
      .where('isCancelled', '==', false);

    const querySnapshot = yield call([emailQuery, emailQuery.get]);

    if (querySnapshot.empty)
      throw Error('Sorry! No reservations found for those dates. Please select new dates and try again');

    const emails = querySnapshot.docs.map((data) => data.data().email);

    if (!emails.length)
      throw Error('Sorry! No reservation emails found for those dates. Please select new dates and try again');

    const uniqueEmails = [...new Set(emails)];
    debug('Reservation emails found', uniqueEmails);

    const batch = firestore.batch();

    const nanoid = customAlphabet('0123456789ABCDEFGJKLMNOPQRSTUVWXYZ', 6);
    const id = nanoid();

    uniqueEmails.forEach((email) => {
      const emailRef = firestore.collection('mail').doc(`${email}-${id}`);
      const generatedEmail = generateEmail(email, html, subjectLine);
      batch.set(emailRef, generatedEmail);
    });

    yield call([batch, batch.commit]);
    debug('Batch call made');

    yield put(sendEmail.success());
  } catch (e) {
    debug('Saga error', e.message);
    yield put(sendEmail.error(e.message));
  }
}

export default function* () {
  yield takeLatest(SEND_EMAIL.PENDING, sendEmailSaga);
}
