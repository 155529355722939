import styled from 'styled-components';
import { media, type } from 'styles';

export const Wrapper = styled.div`
  position: relative;
  padding: 20px 16px 7px;
  border-radius: 8px;
  border: ${({ hasError, theme }) => (hasError
    ? `2px solid ${theme.container.border.error}`
    : `2px solid ${theme.container.border.default}`
  )};
  background: ${({ theme }) => theme.container.background};
`;

export const Input = styled.input`
  width: 100%;
  height: 32px;
  background-color: transparent;
  border: 0;
  color: ${({ theme }) => theme.input.color.default};
  font-weight: ${type.weight.semiBold};
  outline: 0;
  box-shadow: none;

  /* Remove X button (clear field) from IE and Edge */
  &::-ms-clear {
    display: none;
  }

  ${media.min.baseline`font-size: 1.8rem;`}
`;

export const Label = styled.label`
  position: absolute;
  left: 18px;
  font-size: 1.2rem;
  font-weight: ${type.weight.semiBold};
  transition: all .1s ease;
  top: 18px;
  color: ${({ hasError, theme }) => (hasError
    ? theme.label.color.error
    : theme.label.color.default
  )};

  ${media.min.desktop`
    left: 32px;
    font-size: 1.8rem;
  `}

  /*
    Removing -webkit-autofill as it stops IE11 and Firefox floating label
  */
  ${Input}:focus + &,
  ${Input}:valid + & {
    font-size: 1.2rem;
    left: 16px;
    top: 7px;

    ${media.min.desktop`font-size: 1.4rem;`}
  }
`;

export const TooltipWrapper = styled.div`
  display: none;
  position: absolute;
  bottom: 80px;
  left: -4px;
  z-index: 3;

  ${Input}:focus ~ & {
    display: block;
  }
`;

export const PopupWrapperExtended = styled.div`
  border-radius: 8px;
  padding: 18px 0;
`;

export const TooltipText = styled.div`
  font-size: ${type.scale.centi.size};
  color: #4b5064;
  text-align: left;
  padding: 0 18px;
`;
