import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { errorSelector, isLoadingSelector } from 'store/admin/selectors';

import Portal from './Portal';

const mapStateToProps = (state) => ({
  error: errorSelector(state),
  isLoading: isLoadingSelector(state),
});

export const PortalContainer = ({
  customMessage,
  dayAvailabilityOptions,
  dayOptions,
  error,
  isLoading,
  onCustomMessageChange,
  onDayAvailabilityChange,
  onOvernightAvailabilityChange,
  onPortalToggle,
  onSave,
  onTrailheadAvailabilityChange,
  overnightAvailabilityOptions,
  overnightOptions,
  title,
  trailheadAvailabilityOptions,
}) => {
  useEffect(() => {
    if (error) toast.error(error);
  }, [error]);

  return (
    <Portal
      customMessage={customMessage}
      dayAvailabilityOptions={dayAvailabilityOptions}
      dayOptions={dayOptions}
      isLoading={isLoading}
      onCustomMessageChange={onCustomMessageChange}
      onDayAvailabilityChange={onDayAvailabilityChange}
      onOvernightAvailabilityChange={onOvernightAvailabilityChange}
      onPortalToggle={onPortalToggle}
      onSave={onSave}
      onTrailheadAvailabilityChange={onTrailheadAvailabilityChange}
      overnightAvailabilityOptions={overnightAvailabilityOptions}
      overnightOptions={overnightOptions}
      title={title}
      trailheadAvailabilityOptions={trailheadAvailabilityOptions}
    />
  );
};

PortalContainer.propTypes = {
  customMessage: PropTypes.string,
  dayAvailabilityOptions: PropTypes.arrayOf(PropTypes.any),
  dayOptions: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onCustomMessageChange: PropTypes.func.isRequired,
  onDayAvailabilityChange: PropTypes.func.isRequired,
  onOvernightAvailabilityChange: PropTypes.func.isRequired,
  onPortalToggle: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onTrailheadAvailabilityChange: PropTypes.func.isRequired,
  overnightAvailabilityOptions: PropTypes.arrayOf(PropTypes.any),
  overnightOptions: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  trailheadAvailabilityOptions: PropTypes.arrayOf(PropTypes.any),
};

PortalContainer.defaultProps = {
  customMessage: null,
  dayAvailabilityOptions: [],
  error: null,
  overnightAvailabilityOptions: [],
  trailheadAvailabilityOptions: [],
};

export default connect(mapStateToProps, null)(PortalContainer);
