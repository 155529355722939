import styled from 'styled-components';
import { colors, media, type } from 'styles';
import TransparentButton from 'styles/components/TransparentButton';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(4, 15, 30, 0.6);
  height: 100%;
  width: 100%;
`;

export const ModalContentWrapper = styled.div`
  position: relative;
  background-color: ${colors.white};
  border-radius: 8px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1), 0 3px 12px rgba(0, 0, 0, 0.1);
  margin: 16px 8px;
  max-height: 600px;
  overflow-y: auto;
  padding: 32px;

  ${media.min.tablet`
    margin: 16px 14px;
    padding: 28px;
  `}
`;

export const CloseButton = styled(TransparentButton)`
  position: absolute;
  top: 25px;
  right: 25px;
  width: 18px;
  height: 25px;
  font-size: 3.2rem;
  font-weight: ${type.weight.bold};
  line-height: 0.8;
  color: #a4abbb;
  outline: none;
`;
