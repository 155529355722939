import Debug from 'debug';
import { call, put, takeLatest } from 'redux-saga/effects';

import { generateCounterData } from './csvUtils';

import { firestore } from '../../../firebase';
import { DOWNLOAD_COUNTER_DATA, downloadCounterData } from '../actions';

const debug = Debug('sgwa:client-admin:store:downloads:sagas:downloadCounterData');

export function* downloadCounterDataSaga({ payload }) {
  const { counterDates, locationType } = payload;

  debug('Saga called', counterDates);
  const { end, start } = counterDates.selectedDates;

  try {
    const ref = firestore.doc(`${locationType}/main`).collection('events');
    const refQuery = ref.where('timeStamp', '>=', start).where('timeStamp', '<=', end);

    const querySnapshot = yield call([refQuery, refQuery.get]);

    if (querySnapshot.empty) throw Error('Sorry! No data found for those dates. Please try again');
    debug('Counter data found', querySnapshot);

    const csv = [];

    const generatedCSV = generateCounterData(querySnapshot, csv);

    debug('Counter data CSV ready', generatedCSV);

    yield put(downloadCounterData.success(generatedCSV));
  } catch (e) {
    debug('Saga error', e.message);
    yield put(downloadCounterData.error(e.message));
  }
}

export default function* () {
  yield takeLatest(DOWNLOAD_COUNTER_DATA.PENDING, downloadCounterDataSaga);
}
