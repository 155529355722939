import React from 'react';
import PropTypes from 'prop-types';

import EditReservationModal from 'components/shared/EditReservationModal';
import DocumentSummary from 'components/shared/DocumentSummary';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import Button from 'components/shared/Button';
import {
  CancelButtonWrapper,
  InstructionText,
  LoadingText,
  LoadingWrapper,
  OptionButtonWrapper,
  ReservationButtonWrapper,
  SubmitButtonWrapper,
  SubTitle,
  TextFieldInput,
  TextFieldWrapper,
  Title,
  Wrapper,
} from './style';

export const Reservations = ({
  didCancel,
  didResendEmail,
  isEditModalOpen,
  isLoading,
  onCancelReservation,
  onChange,
  onEditModalToggle,
  onResendEmail,
  onReset,
  onSubmit,
  permitId,
  reservation,
}) => {
  let content;
  if (isLoading) {
    content = (
      <LoadingWrapper>
        <LoadingText>Working...</LoadingText>
        <LoadingSpinner />
      </LoadingWrapper>
    );
  } else if (didCancel || didResendEmail) {
    const text = didCancel ? 'Reservation Successfully Cancelled' : 'Reservation Successfully Resent';
    content = (
      <>
        <Title>{text}</Title>
        <SubTitle>Find another reservation?</SubTitle>
        <form>
          <Button onClick={onReset}>Yes</Button>
        </form>
      </>
    );
  } else if (reservation) {
    content = (
      <>
        <Title>Reservation Found</Title>
        <InstructionText>
          Please note that editing a reservation will automatically send the newly edited permit to the user. You do not
          need to press &quot;Resend Email&quot;.
        </InstructionText>
        <OptionButtonWrapper>
          <ReservationButtonWrapper>
            <Button onClick={onReset}>Look up another reservation</Button>
          </ReservationButtonWrapper>
          <ReservationButtonWrapper>
            <Button onClick={onEditModalToggle}>Edit Reservation</Button>
          </ReservationButtonWrapper>
          <ReservationButtonWrapper>
            <Button onClick={onResendEmail}>Resend Reservation Email to User</Button>
          </ReservationButtonWrapper>
        </OptionButtonWrapper>
        <DocumentSummary isDetailed reservation={reservation} />
        <CancelButtonWrapper>
          <Button variant="red" onClick={onCancelReservation}>
            Cancel Reservation
          </Button>
        </CancelButtonWrapper>

        {isEditModalOpen && (
          <EditReservationModal onClose={onEditModalToggle} permitId={permitId} reservation={reservation} />
        )}
      </>
    );
  } else {
    content = (
      <>
        <InstructionText>
          Please type the permit number the holder received when they made their booking
        </InstructionText>
        <form>
          <TextFieldWrapper>
            <TextFieldInput
              id="permitId"
              name="permitId"
              onChange={(event) => onChange(event.target.value)}
              placeholder="Type permit number here..."
              required
              type="text"
              value={permitId || ''}
            />
          </TextFieldWrapper>
          <SubmitButtonWrapper>
            <Button disabled={!permitId} onClick={onSubmit}>
              Submit
            </Button>
          </SubmitButtonWrapper>
        </form>
      </>
    );
  }

  return <Wrapper>{content}</Wrapper>;
};

Reservations.propTypes = {
  didCancel: PropTypes.bool.isRequired,
  didResendEmail: PropTypes.bool.isRequired,
  isEditModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCancelReservation: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onEditModalToggle: PropTypes.func.isRequired,
  onResendEmail: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  permitId: PropTypes.string,
  reservation: PropTypes.objectOf(PropTypes.any),
};

Reservations.defaultProps = {
  permitId: null,
  reservation: null,
};

export default Reservations;
