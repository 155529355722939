import styled from 'styled-components';
import { media } from 'styles';

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
`;

export const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 500;
  color: #000000;

  ${media.twoDimMin.tablet`font-size: 2.6rem;`}
`;

export const SubTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 30px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const InstructionText = styled.h3`
  font-size: 2.2rem;
  color: #626262;
  font-weight: 400;
  margin: 0 0 30px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const LoadingText = styled.h3`
  font-size: 2.2rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 30px 0;
  text-align: center;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const BoldSubTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 400;
  color: #000000;
  margin: 0 0 30px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const LinkTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 300;
  color: #2e5941;
  margin: 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const Wrapper = styled.div`
  padding: 42px 36px;
`;

export const TextFieldWrapper = styled.div`
  position: relative;
  border-bottom: 1px solid #2e5941;
  width: 100%;

  ${media.min.mobileLandscape`width: 500px;`}
`;

export const TextFieldInput = styled.input`
  width: 100%;
  background-color: transparent;
  border: 0;
  color: #2e5941;
  outline: 0;
  box-shadow: none;
  font-size: 2.2rem;
  font-weight: 500;
  padding: 12px 70px 12px 0;
  ${media.twoDimMin.tablet`font-size: 2.4rem;`}

  &::placeholder {
    color: #d0d0d0;
    font-weight: 400;
  }
`;

export const SubmitButtonWrapper = styled.div`
  margin-top: 12px;
`;

export const OptionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const CancelButtonWrapper = styled.div`
  margin: 12px 0 30px 0;
  float: right;
`;

export const ReservationButtonWrapper = styled.div`
  margin: 0 4px;
`;
