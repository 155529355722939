import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track, { TrackingPropType } from 'react-tracking';

import * as navMenuOptions from 'constants/navMenuOptions';
import { isAdminUserSelector } from 'store/app/selectors';

import * as appActions from 'store/app/actions';

import PortalLayout from 'components/shared/PortalLayout';

import Reservations from './Options/Reservations';
import Downloads from './Options/Downloads';
import Admin from './Options/Admin';
import Email from './Options/Email';
import PortalOptions from './Options/PortalOptions';

const componentMapping = {
  [navMenuOptions.CUCA_ADMIN]: Admin,
  [navMenuOptions.DOWNLOADS]: Downloads,
  [navMenuOptions.EMAIL]: Email,
  [navMenuOptions.PORTAL_OPTIONS]: PortalOptions,
  [navMenuOptions.RESERVATIONS]: Reservations,
  [navMenuOptions.SGW_ADMIN]: Admin,
};

const mapStateToProps = (state) => ({
  isAdmin: isAdminUserSelector(state),
});

const mapDispatchToProps = {
  logout: appActions.logout,
};

export const Portal = ({ isAdmin, logout, tracking: { trackEvent } }) => {
  const [selectedComponent, setSelectedComponent] = useState(navMenuOptions.RESERVATIONS);

  const handleNavChange = (componentChoice) => {
    setSelectedComponent(componentChoice);

    trackEvent({ componentChoice, event: 'Navigation Menu Option Selected' });
  };
  const StepComponent = componentMapping[selectedComponent];

  return (
    <PortalLayout isAdmin={isAdmin} onNavChange={handleNavChange} onLogout={logout}>
      <StepComponent selectedComponent={selectedComponent} />
    </PortalLayout>
  );
};

Portal.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  tracking: TrackingPropType,
};

Portal.defaultProps = {
  tracking: null,
};

const trackedPortal = track({
  page: 'Portal',
})(Portal);

export default connect(mapStateToProps, mapDispatchToProps)(trackedPortal);
