/* eslint-disable brace-style */

import Debug from 'debug';
import { call, put } from 'redux-saga/effects';
import UAParser from 'ua-parser-js';

import 'lib/sentry';
import { getQueryParams } from 'lib/queryParams';

import { setUserAgent } from 'store/userAgent/actions';

const debug = Debug('sgwa:client-admin:store:app:sagas:sessionManager');

function* setUserAgentAction(queryParams) {
  const userAgent = UAParser();
  yield put(
    setUserAgent({
      deviceTypeOverride: queryParams.d,
      userAgent,
    })
  );
}

export default function* initSessionManager() {
  debug('Saga called');

  const queryParams = yield call(getQueryParams);
  yield* setUserAgentAction(queryParams);
}
