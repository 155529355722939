import React, { Component } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import * as Sentry from '@sentry/react';

import Config from 'config'; // eslint-disable-line
import { appTrackingOptions } from 'lib/analytics';

import ErrorView from './ErrorView';

@track({}, appTrackingOptions)
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (Config.isAnalyticsEnabled) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }
  }

  render() {
    return this.state.hasError ? <ErrorView /> : this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
