import Debug from 'debug';
import { call, put, takeLatest } from 'redux-saga/effects';

import { firestore } from '../../../firebase';
import { PORTAL_CHANGE, portalChange } from '../actions';

const debug = Debug('sgwa:permit-portal:store:admin:sagas:portalChange');

export function* portalChangeSaga({ payload: data }) {
  debug('Saga called', data);

  try {
    const ref = firestore.doc('portal/data');
    yield call([ref, ref.set], data, { merge: true });

    yield put(portalChange.success(data));
  } catch (e) {
    debug('Saga error', e.message);
    yield put(portalChange.error(e.message));
  }
}

export default function* () {
  yield takeLatest(PORTAL_CHANGE.PENDING, portalChangeSaga);
}
