import Moment from 'moment';
import { customAlphabet } from 'nanoid';

import backendIds from 'constants/backendIds';

import generateEmail from 'lib/generateEmail';
import { firebaseInstance, firestore } from '../../../firebase';

export function batchCancelDayReservation(batch, userReservation) {
  const { locationType, permitNumber, reservationType, selection } = userReservation;
  const reservationTypeId = backendIds[reservationType];

  let date;
  let id;
  let backendId;

  if (userReservation.date) {
    ({ id } = userReservation.trail);
    date = Moment(userReservation.date.utc).format('YYYY-MM-DD');
    backendId = userReservation.docId;
  } else {
    ({ backendId } = userReservation);
    ({ id } = selection);
    ({ date } = selection);
  }

  const updatePayload = {
    [id]: {
      [date]: {
        remaining: firebaseInstance.firestore.FieldValue.increment(1),
      },
    },
  };

  const ref = firestore.doc(`${locationType}/${reservationTypeId}`).collection('reservations').doc(backendId);
  batch.set(ref, updatePayload, { merge: true });

  const userReservationRef = firestore
    .collection(`${locationType}`)
    .doc('main')
    .collection('reservations')
    .doc(permitNumber);
  batch.set(userReservationRef, { ...userReservation, isCancelled: true }, { merge: true });
}

export function batchCancelOvernightReservation(batch, userReservation) {
  const { locationType, permitNumber, reservationType, selection } = userReservation;
  const reservationTypeId = backendIds[reservationType];

  if (userReservation.date) {
    Object.keys(userReservation.date).forEach((siteId) => {
      const { end, start } = userReservation.date[siteId].dates;

      const momentStart = Moment(start);
      const now = momentStart.clone();

      while (now.isBefore(end)) {
        const dateKey = now.format('YYYY-MM-DD');
        const queryMonth = Moment(dateKey).clone().format('MMMM-YYYY');

        const updatePayload = {
          [siteId]: {
            [dateKey]: {
              remaining: firebaseInstance.firestore.FieldValue.increment(1),
            },
          },
        };

        const ref = firestore.doc(`${locationType}/${reservationTypeId}`).collection('reservations').doc(queryMonth);
        batch.set(ref, updatePayload, { merge: true });

        now.add(1, 'days');
      }
    });
  } else {
    Object.keys(selection).forEach((siteId) => {
      const { end, start } = selection[siteId];
      const { date, id } = start;

      const momentStart = Moment(date);
      const now = momentStart.clone();

      while (now.isBefore(end.date)) {
        const dateKey = now.format('YYYY-MM-DD');
        const queryMonth = Moment(dateKey).clone().format('MMMM-YYYY');

        const updatePayload = {
          [id]: {
            [dateKey]: {
              remaining: firebaseInstance.firestore.FieldValue.increment(1),
            },
          },
        };

        const ref = firestore.doc(`${locationType}/${reservationTypeId}`).collection('reservations').doc(queryMonth);
        batch.set(ref, updatePayload, { merge: true });

        now.add(1, 'days');
      }
    });
  }

  const userReservationRef = firestore
    .collection(`${locationType}`)
    .doc('main')
    .collection('reservations')
    .doc(permitNumber);
  batch.set(userReservationRef, { ...userReservation, isCancelled: true }, { merge: true });
}

export const generateBatchEmail = (batch, payload, trailheadOptions) => {
  const { email, pdf, permitNumber } = payload;

  const nanoid = customAlphabet('0123456789ABCDEFGJKLMNOPQRSTUVWXYZ', 6);
  const id = nanoid();

  const data = generateEmail(pdf, payload, trailheadOptions);

  const ref = firestore.collection('mail').doc(`${email}-${permitNumber}-${id}`);
  batch.set(ref, data);
};
