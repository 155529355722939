import { createAction, createAsyncAction, createAsyncTypes } from 'lib/reduxUtils';

export const LOGOUT = 'app/logout';
export const logout = createAction(LOGOUT);

// Async
export const INITIALIZE_APP = createAsyncTypes('app/initializeApp');
export const initializeApp = createAsyncAction(INITIALIZE_APP);

export const LOGIN_USER = createAsyncTypes('app/loginUser');
export const loginUser = createAsyncAction(LOGIN_USER);

export const FETCH_MAIN_COLLECTION = createAsyncTypes('app/fetchMainCollection');
export const fetchMainCollection = createAsyncAction(FETCH_MAIN_COLLECTION);
