import styled from 'styled-components';
import { media } from 'styles';

export const EmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

export const InstructionText = styled.h1`
  font-size: 2rem;
  color: #626262;
  margin: 0;

  ${media.twoDimMin.tablet`font-size: 2.2rem;`}
`;

export const OptionWrapper = styled.div`
  margin: 12px 0;
`;

export const SubTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 30px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const ButtonWrapper = styled.div`
  margin-top: 30px;
`;

export const EditorWrapper = styled.div`
  height: 400px;
  overflow: scroll;
  background: white;
  padding: 18px;
`;

export const TextArea = styled.textarea`
  width: 400px;
  padding: 12px;
  resize: none;
  white-space: nowrap;
`;

export const TypeAheadWrapper = styled.div`
  margin: 12px 0;
  width: 300px;
`;
