import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import * as emailActions from 'store/email/actions';
import { didSendEmailSelector, errorEmailSelector, isSendingEmailSelector } from 'store/email/selectors';
import { isAdminUserSelector } from 'store/app/selectors';

import Email from './Email';

const mapStateToProps = (state) => ({
  didSend: didSendEmailSelector(state),
  error: errorEmailSelector(state),
  isAdminUser: isAdminUserSelector(state),
  isSending: isSendingEmailSelector(state),
});

const mapDispatchToProps = {
  resetStore: emailActions.resetStore,
  sendEmail: emailActions.sendEmail,
};

const adminLocationTypeOptions = {
  cucamonga: {
    id: 'cucamonga',
    title: 'Cucamonga Wilderness',
  },
  san_gorgonio: {
    id: 'san_gorgonio',
    title: 'San Gorgonio Wilderness',
  },
};

const cucamongaLocationTypeOptions = {
  cucamonga: {
    id: 'cucamonga',
    title: 'Cucamonga Wilderness',
  },
};

export const EmailContainer = ({ didSend, error, isAdminUser, isSending, resetStore, sendEmail }) => {
  const [dates, setDates] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [subjectLine, setSubjectLine] = useState('');
  const [locationType, setLocationType] = useState(null);

  useEffect(() => {
    if (error) toast.error(error);
  }, [error]);

  useEffect(() => {
    if (didSend) toast.success('Email sent!');
  }, [didSend]);

  useEffect(() => () => resetStore(), []);

  const handleSend = () => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    if (subjectLine && html && dates) {
      sendEmail({
        dates,
        html,
        locationType: locationType.id,
        subjectLine,
      });
    } else {
      toast.error('Please make sure you filled out the date range, subject line and email body');
    }
  };

  const locationTypeOptions = isAdminUser ? adminLocationTypeOptions : cucamongaLocationTypeOptions;
  return (
    <Email
      dates={dates}
      editorState={editorState}
      isLoading={isSending}
      locationTypeOptions={locationTypeOptions}
      onDateChange={setDates}
      onEditorChange={setEditorState}
      onLocationType={(selectedLocationType) => setLocationType(selectedLocationType)}
      onSend={handleSend}
      onSubjectLineChange={setSubjectLine}
    />
  );
};

EmailContainer.propTypes = {
  didSend: PropTypes.bool.isRequired,
  error: PropTypes.string,
  isAdminUser: PropTypes.bool.isRequired,
  isSending: PropTypes.bool.isRequired,
  resetStore: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
};

EmailContainer.defaultProps = {
  error: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailContainer);
