import React from 'react';
import PropTypes from 'prop-types';

import { SingleDatePicker } from 'react-dates';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import Button from 'components/shared/Button';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ToggleSwitch from 'components/shared/ToggleSwitch';

import { ButtonWrapper, DatePickerWrapper, PortalWrapper, InstructionText, OptionWrapper, SubTitle } from './style';

const PortalOptions = ({
  isAdminUser,
  isCalendarFocused,
  isCucamongaEnabled,
  isLoading,
  isPortalMaintenance,
  isSanGorgonioEnabled,
  onCalendarFocused,
  onCucamongaToggle,
  onMaintenanceToggle,
  onSanGorgonioToggle,
  onSave,
  onStartDate,
  startDate,
}) => {
  let content;

  if (isLoading) {
    content = (
      <>
        <SubTitle>Updating...</SubTitle>
        <LoadingSpinner />
      </>
    );
  } else if (isAdminUser) {
    content = (
      <>
        <OptionWrapper>
          <InstructionText>
            This toggle will turn on maintenance or emergency situations mode which will disable the permit portal.
          </InstructionText>
          <ToggleSwitch
            id="portalMaintenance"
            isToggleEnabled={isPortalMaintenance || false}
            onChange={onMaintenanceToggle}
            text={!isPortalMaintenance ? 'Disabled' : 'Enabled'}
          />
        </OptionWrapper>
        <OptionWrapper>
          <InstructionText>
            This toggle will enable or disable the ability for users to make a reservation at the San Gorgonio
            Wilderness.
          </InstructionText>
          <ToggleSwitch
            id="sanGorgonio"
            isToggleEnabled={isSanGorgonioEnabled || false}
            onChange={onSanGorgonioToggle}
            text={isSanGorgonioEnabled ? 'San Gorgonio Wilderness Enabled' : 'San Gorgonio Wilderness Disabled'}
          />
        </OptionWrapper>
        <OptionWrapper>
          <InstructionText>
            This toggle will enable or disable the ability for users to make a reservation at the Cucamonga Wilderness.
          </InstructionText>
          <ToggleSwitch
            id="cucamonga"
            isToggleEnabled={isCucamongaEnabled || false}
            onChange={onCucamongaToggle}
            text={isCucamongaEnabled ? 'Cucamonga Wilderness Enabled' : 'Cucamonga Wilderness Disabled'}
          />
        </OptionWrapper>
        <OptionWrapper>
          <InstructionText>
            Change the start date for all calendars in the permit portal. Example: Today is the 10th and the forest is
            closed to the 14th so you can change the start date to the 14th.
          </InstructionText>
          <DatePickerWrapper>
            <SingleDatePicker
              block
              date={startDate}
              focused={isCalendarFocused}
              id="single-date-picker"
              numberOfMonths={1}
              onDateChange={onStartDate}
              onFocusChange={onCalendarFocused}
              openDirection="up"
              placeholder="Pick a Date"
            />
          </DatePickerWrapper>
        </OptionWrapper>
        <OptionWrapper>
          <ButtonWrapper>
            <Button onClick={onSave}>Save Changes</Button>
          </ButtonWrapper>
        </OptionWrapper>
      </>
    );
  } else {
    content = (
      <>
        <OptionWrapper>
          <InstructionText>
            This toggle will enable or disable the ability for users to make a reservation at the Cucamonga Wilderness.
          </InstructionText>
          <ToggleSwitch
            id="cucamonga"
            isToggleEnabled={isCucamongaEnabled || false}
            onChange={onCucamongaToggle}
            text={isCucamongaEnabled ? 'Cucamonga Wilderness Enabled' : 'Cucamonga Wilderness Disabled'}
          />
        </OptionWrapper>
        <OptionWrapper>
          <ButtonWrapper>
            <Button onClick={onSave}>Save Changes</Button>
          </ButtonWrapper>
        </OptionWrapper>
      </>
    );
  }

  return <PortalWrapper>{content}</PortalWrapper>;
};

PortalOptions.propTypes = {
  isAdminUser: PropTypes.bool.isRequired,
  isCucamongaEnabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPortalMaintenance: PropTypes.bool.isRequired,
  isSanGorgonioEnabled: PropTypes.bool.isRequired,
  onCucamongaToggle: PropTypes.func.isRequired,
  onMaintenanceToggle: PropTypes.func.isRequired,
  onSanGorgonioToggle: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onStartDate: PropTypes.func.isRequired,
  startDate: PropTypes.any.isRequired,
};

export default PortalOptions;
