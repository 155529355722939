/* eslint-disable sort-keys */
import Moment from 'moment';

export const isBeforeDay = (a, b) => {
  if (!Moment.isMoment(a) || !Moment.isMoment(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
};

export const isInclusivelyAfterDay = (a, b) => {
  if (!Moment.isMoment(a) || !Moment.isMoment(b)) return false;
  return !isBeforeDay(a, b);
};

export const areArraysEqual = (a, b) =>
  Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);

export const addRemoveElementFromArray = (array, value) =>
  array.includes(value) ? array.filter((item) => item !== value) : [...array, value];
