import Debug from 'debug';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Config from 'config'; // eslint-disable-line

import awaitAsyncAction from 'lib/awaitAsyncAction';

import { sgwCustomNoteSelector, cucaCustomNoteSelector } from 'store/app/selectors';
import { cucaDayOptionsSelector, sgwDayOptionsSelector } from 'store/reservations/selectors';

import { GENERATE_PERMIT, generatePermit, EDIT_RESERVATION, editReservation } from '../actions';
import { generateBatchEmail } from './reservationUtils';

import { firestore } from '../../../firebase';

const debug = Debug('sgwa:client-admin:store:reservations:sagas:editReservation');

export function* editReservationSaga({ payload }) {
  const { editedReservation, locationType, permitNumber } = payload;
  debug('Saga called with edited fields', editedReservation);

  const batch = firestore.batch();
  try {
    let customNote;
    let trailheadOptions;

    if (locationType === 'cucamonga') {
      customNote = yield select(cucaCustomNoteSelector);
      trailheadOptions = yield select(cucaDayOptionsSelector);
    } else {
      customNote = yield select(sgwCustomNoteSelector);
      trailheadOptions = yield select(sgwDayOptionsSelector);
    }

    const userPayload = {
      ...editedReservation,
      customNote,
      locationType,
      permitNumber,
    };

    debug('User payload', userPayload);

    yield put(generatePermit(userPayload));
    const [success, error] = yield call(awaitAsyncAction, GENERATE_PERMIT);

    if (error) throw error.payload;

    const emailPDF = `data:application/pdf;base64,${success.payload}`;
    yield call(
      generateBatchEmail,
      batch,
      {
        ...userPayload,
        pdf: emailPDF,
      },
      trailheadOptions
    );

    // if (Config.environment === 'prod') {
    //   debug('Sending copy email to permits@sgwa.org');

    //   const adminEmail = 'permits@sgwa.org';
    //   yield call(
    //     generateBatchEmail,
    //     batch,
    //     {
    //       ...userPayload,
    //       email: adminEmail,
    //       pdf: emailPDF,
    //     },
    //     trailheadOptions
    //   );
    // }

    const reservationRef = firestore
      .collection(`${locationType}`)
      .doc('main')
      .collection('reservations')
      .doc(permitNumber);

    batch.set(reservationRef, editedReservation, { merge: true });

    debug('Reservation edit batched');

    yield call([batch, batch.commit]);
    debug('Batch set was successfully made');

    yield put(editReservation.success(editedReservation));
  } catch (e) {
    debug('Saga error', e.message);
    yield put(editReservation.error(e.message));
  }
}

export default function* () {
  yield takeLatest(EDIT_RESERVATION.PENDING, editReservationSaga);
}
